import React, {Component} from 'react'
import InputGroup from '../InputGroup/InputGroup'
import BidAskGroup from '../InputGroup/BidAskGroup';
export default class Card extends Component {
  state = {
    selected: false,
    assetPricing: this.props.assetPricing
  }


  onChange = (ev, name) => {

    if(name === 'bidAskGroupPush'){
     this.setState({
        assetPricing: { 
          ...this.state.assetPricing, // merge with the original `state.items`
          bidAsk: this.state.assetPricing.bidAsk.concat({date:'', bid: '',ask: ''}) 
        } 
      });
    } else if(name === 'bidAskGroupPull'){ // Handle bubble up from BidAskGroup component
      let index = ev;
      // console.log(ev);
      this.setState({
        assetPricing: { 
          ...this.state.assetPricing, // merge with the original `state.items`
          bidAsk: this.state.assetPricing.bidAsk.filter((data, idx) => idx !== index)
        } 
      });

      
    } else if(name === 'bidAskGroup'){ // Handle bubble up from BidAskGroup component
      let value = ev.target.value
      let updateName = ev.target.name;
      let i = ev.target.getAttribute('data-index-number'); // "3"

      var bidAsk = {...this.state.assetPricing.bidAsk}
      bidAsk[i][updateName] = value;
      this.setState({bidAsk});

      

    } else {
      const value = ev.target.value
      this.setState(prevState => {
        const assetPricing = prevState.assetPricing
        assetPricing[name] = value
        return prevState
      })
    }

   
  }



  render() {
    return (
      <div className="card">
        <div className="card-header">
          <button
            className="btn btn-link"
            type="button"
            data-toggle="collapse"
            onClick={() =>
              this.setState(prevState => ({selected: !prevState.selected}))
            }
          >
            {this.state.assetPricing.name}
          </button>
        </div>

        <div className={`collapse ${this.state.selected ? 'show' : ''}`}>
          <div className="card-body">
           
              <InputGroup
                  key="name"
                  name="name"
                  value={this.state.assetPricing["name"]}
                  onChange={this.onChange}
                  type="text"
                />
                <InputGroup
                  key="pricingId"
                  name="pricingId"
                  value={this.state.assetPricing["pricingId"]}
                  onChange={this.onChange}
                  type="number"
                  placeholder="Set as Unique Number"
                />


            {Object.keys(this.state.assetPricing).map(name =>
             name === "bidAsk" ? (
                <BidAskGroup
                  key={name}
                  value={this.state.assetPricing[name] || ''}
                  onChange={this.onChange}
                />

             ): ('')
            )}
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.props.delete(this.state.assetPricing)}
                disabled={this.props.btnsDisabled}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => this.props.update(this.state.assetPricing)}
                disabled={this.props.btnsDisabled}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
