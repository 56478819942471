import React from 'react'

import './TradeConfirmModal.css'

export default function TradeConfirmModal(changeConfirmed, bidConfirmed, modalShow, selectedClaim) {

  let title = '';
  if(selectedClaim.length > 0 && selectedClaim[0].tradingStatus == 'true'){
    title = "Seller Engaged";
  } else if(bidConfirmed){
    title = 'Bid Confirmation';
  } else {
    title = 'Trade Confirmation';
  }
 
  return (
    <div
      className="modal confirm-modal"
      tabIndex="-1"
      role="dialog"
      data-show={`${modalShow}`}
      data-focus={`${modalShow}`}
      style={{display: modalShow ? 'block' : 'none'}}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{ title }</h5>
          </div>
          <div className="modal-body">
            <p>
              { bidConfirmed == true ? "Have you completed all your diligence on the Claim? \
              By clicking on the “Yes” button below, you confirm you will close and pay the Consideration to the Seller by wire transfer \
              within two business days if your unconditional Firm Bid at Lower Rate Bid is accepted." :
              "Are you sure? By clicking on the “Yes” button below you confirm that you are placing a Firm Bid to unconditionally purchase the Claim and pay the Consideration to Seller by wire transfer within two business days. Note, your Firm Bid may be subject to engaged buyer notification. See Bidding Protocol for details." }
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => changeConfirmed(false, bidConfirmed)}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => changeConfirmed(true, bidConfirmed)}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
