import React from 'react'
import './index.css'
import ListClaim from './ListClaim.js'
import ConfirmClaim from './ConfirmClaim.js'
import TradeConfirm from './TradeConfirm.js'

export default class Steps extends React.Component {
  constructor() {
    super();

    this.state = {
      createClaimStep: 1,
      selectedClaims: []
    };

    this.setCreateClaimStep = this.setCreateClaimStep.bind(this);
    this.getStepComponent = this.getStepComponent.bind(this);
  }

  setCreateClaimStep(createClaimStep, claims) {
    this.setState({
      createClaimStep: createClaimStep,
      selectedClaims: claims
    });
  }

  render() {
    return (
      <div id="createClaimSteps" className="section-signup">
        {this.getStepComponent(this.state.createClaimStep)}
      </div>
    )
  }

  getStepComponent(step) {
    switch (step) {
      case 1:
        return <ListClaim setCreateClaimStep={this.setCreateClaimStep} />

      case 2:
        return (
          <ConfirmClaim
            setCreateClaimStep={this.setCreateClaimStep}
            selectedClaims={this.state.selectedClaims} />
        );

      case 3:
        return <TradeConfirm selectedClaims={this.state.selectedClaims} />

      default:
        return <React.Fragment />
    }
  }
}
