import React from 'react'
import {Button} from 'react-bootstrap'
import history from '../../history'
import {connect} from 'react-redux'
import * as Scroll from 'react-scroll'
import './footer.css'

const animateScroll = Scroll.animateScroll

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.checkLocation = this.checkLocation.bind(this)
    this.year = new Date().getFullYear()
  }

  checkLocation(position) {
    var oldIsHome =
      history.location.pathname === '/' || history.location.pathname === '/home'

    if (oldIsHome === false) {
      history.push('/')
    }

    this.forceUpdate()

    setTimeout(function() {
      var destination = document.querySelector('#' + position)
      const elementPosition = destination.offsetTop - 30
      animateScroll.scrollTo(elementPosition, {
        smooth: true,
        duration: 500
      })
    }, 200)
  }

  render() {
    return (
      <div id="footer" className="section">
        <footer className="page-footer">
          <div className="container-fluid text-md-left">
            <div className="row">
              <img
                src="/assets/images/logo-one-white.png"
                className="img-fluid logo"
                alt="claims market logo"
              />
            </div>
            <div className="row">
              <div className="col-md-6 mt-md-0 mt-3">
                <p>
                  Claims Market is a platform dedicated to simplifying the
                  market for trade claims. Our goal is to bring together Sellers
                  and Buyers of claims through a transparent marketplace that
                  reduces transaction costs.
                </p>
              </div>
              <hr className="clearfix w-100 d-md-none pb-3" />
              <div className="col-md-2 mb-md-0 mb-3">
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.checkLocation('section-two')}
                    >
                      How it Works
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-three-background')
                      }
                    >
                      SAC (Simple Assignment of Claim)
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-five-background')
                      }
                    >
                      Seller's Agreement
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-four-background')
                      }
                    >
                      Buyer's Agreement
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 mb-md-0 mb-3">
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-one-background')
                      }
                    >
                      Claims Offered
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.props.history.push('/transactions')}
                    >
                      Transactions
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.checkLocation('faq')}
                    >
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.checkLocation('spta-background')}
                    >
                      SPTA (Simple Pass Through Assignment)
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.checkLocation('section-six')}
                    >
                      Legal
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-seven-background')
                      }
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 mb-md-0 mb-3 icons">
                <a
                  href="https://www.linkedin.com/company/cherokee-acquisition/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/linkedin.png"
                    className="img-fluid icon"
                    alt="linkedin"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCrL-kqeiZGz7_WH9mAXKTrg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/icons/youtube.png"
                    className="img-fluid icon icon-right"
                    alt="youtube"
                  />
                </a>
                <a
                  href="https://twitter.com/claims_market"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/icons/x-logo.svg"
                    className="img-fluid icon icon-right twitter"
                    alt="twitter"
                  />
                </a>
                
              </div>
            </div>
          </div>
          <div className="contact-container">
            {!this.props.account.name && (
              <div className="onboarding-btn-container">
                <Button className="btn-default" href="/signin">
                  Log In
                </Button>
                <Button className="btn-default" href="/signup">
                  Sign Up
                </Button>
              </div>
            )}
          </div>
          <div className="footer-copyright text-center py-3">
            <a href="http://cherokeeacq.com/" target="_blank">
              © {this.year} Cherokee Hybrid Markets, Inc.
            </a>
          </div>
          <div className="footer-meeplabs text-center">
            <a href="https://ravennainteractive.com/" target="_blank">
              Site Developed by Ravenna
            </a>
          </div>
        </footer>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account
  }
}

export default connect(mapState)(Footer)
