import React from 'react'
import {connect} from 'react-redux'
import {Image, Button} from 'react-bootstrap'

import './AdminUserItem.css'
class AdminUserItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        ...this.props.info
      },
      tabOpen: false
    }
    this.handleTabChange = this.handleTabChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onCheckValueChange = this.onCheckValueChange.bind(this)
  }

  handleTabChange() {
    this.setState(prevState => ({
      tabOpen: !prevState.tabOpen
    }))
  }

  onInputChange(event) {
    const key = event.target.name
    const value = event.target.value
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [key]: value
      }
    }))
  }

  onCheckValueChange(event) {
    const checked = event.target.checked;
    const key = event.target.name;
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [key]: checked
      }
    }))
  }

  render() {
    const labelMap = {
      name: 'Name',
      title: 'Title',
      company: 'Company',
      email: 'E-mail',
      phone: 'Phone',
      password: 'Password',
      accountType: 'Account Type',
      canViewPricing: 'Can View Pricing',
      isAdmin: 'Is Admin',
      bankruptcyCase: 'Bankruptcy Case',
      claimAmount: 'Claim Amount',
      askingPrice: 'Asking Price',
      agreementSigned: 'Agreement Signed',
      approved: 'Approved',
      selectedFile: 'Account Statement'
    }
    return (
      <div id="admin-item">
        <div className="subjectContainer">
          <div className="subjectContainer-left">
            <span>{this.props.info.name} - {this.props.info.id} </span>
            {this.props.info.approved && (
              <span>Approved</span>
            )}
            {this.props.info.selectedFile && (
              <span>Statement</span>
            )}
            
          </div>
          {this.state.tabOpen ? (
            <Image
              src="/assets/icons/minus.svg"
              onClick={this.handleTabChange}
            />
          ) : (
            <Image
              src="/assets/icons/plus.svg"
              onClick={this.handleTabChange}
            />
          )}
        </div>
        {this.state.tabOpen && (
          <div className="editFormContainer">
            {Object.keys(this.props.info).map(item => {
              if (item !== 'id' && item !== 'password' && item !== 'approved' && item !== 'selectedFile' && item != 'agreementSigned') {
                return (
                  <div key={item} className="input-container">
                    <label className="option">{labelMap[item]}</label>
                    <input
                      type="text"
                      name={item}
                      value={this.state.form[item]}
                      onChange={this.onInputChange}
                    />
                  </div>
                )
              } else if (item === 'selectedFile'){
                return (
                  <div key={item} className="input-container">
                    <label className="option">{labelMap[item]}</label>
                    <div className="account-statement">
                    <a href={`https://s3.us-east-2.amazonaws.com/claims-market-assets/accounts/${this.state.form[item]}`} target="_blank">Account Statement</a>
                    </div>
                  </div>
                )
              } else if (item === 'approved' || item === 'agreementSigned'){
                return (
                  <div key={item} className="file-container">
                    <label className="option">{labelMap[item]}</label>
                    <input
                      type="checkbox"
                      name={item}
                      checked={this.state.form[item]}
                      onChange={this.onCheckValueChange}
                    />
                  </div>
                )

              } else return ''
            })}
            {this.props.info['Agreement Signed'] && <a>See Signed Agreement</a>}
            <div className="btn-container">
              <Button
                className="btn-default"
                style={{backgroundColor: '#DC7633'}}
                onClick={() => this.props.deleteItemSubmit(this.props.info.id)}
              >
                DELETE
              </Button>
              <Button
                className="btn-default"
                onClick={() => {
                  this.props.editItemFormSubmit(
                    this.props.info.id,
                    this.state.form
                  )
                  this.setState({tabOpen: false})
                }}
              >
                SAVE
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account
  }
}

const mapDispatch = dispatch => {
  return {
    submitSignInForm(userInfo) {
      dispatch(auth(userInfo, 'signin'))
    }
  }
}

export default connect(mapState, mapDispatch)(AdminUserItem)
