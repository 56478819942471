import React, {Component} from 'react'
import {Row, Col} from 'react-bootstrap'
import './styles/SectionFtx.css'
import axios from 'axios'
import SortTable from './TransactionsTable';
import moment from 'moment'

import { 
  LineChart, 
  Line, 
  CartesianGrid, 
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend,
  ResponsiveContainer } from 'recharts';

//const SectionFtx = () => {
export default class SectionFtx extends Component {

    state = {
        pricing: [],
        bid: '',
        ask: '',
        total: 0,
        list: [],
        data: [],
        count: 0,
    }
    componentDidMount() {  
        axios
            .get(`/api/asset-pricing`)
            .then(response => {
              const data = normalizeData(response.data)
              let ftx = data.find(o => o.name === 'FTX Trading Ltd.');
              // console.log(ftx);
              let latestPricing = ftx.data[ftx.data.length - 1];  
              //const list = normalizeChartData(ftx);       
              //// console.log(list);
              this.setState({bid: latestPricing.Bid, ask: latestPricing.Ask, data: ftx.data})
          })


        axios.get('/api/transaction')
          .then(response => {
            let transactions = response.data;
            for (const i in transactions) {
              let transaction = transactions[i];		
              transaction.minAmount = `$${Number(transaction.minAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`; 
            }
            this.setState({total: calculateTotalAmount(transactions), count: transactions.length})
          });
  
    }
   
  render (){
    return (
    <div id="section-ftx-background">
      <div id="section-ftx" className="section section-ftx">
        <Row>
          <Col lg={6} className="image-section">
            <div>
                <h2>FTX Claims Pricing</h2>
                <p>Cherokee Acquisition is providing liquidity on FTX claims. Please note that the below prices are for claims over $1m. Actual prices may be higher or lower, depending on claim size and quality.  Kindly, contact us for the most up to date pricing.</p>  
            </div>

            <div className='chart-wrapper'>
            <ResponsiveContainer
              width="100%"
              aspect={1.5}
              maxHeight={480}
              minWidth={300}
            >
             <LineChart
                data={this.state.data}
                margin={{top: 5, right: 20, bottom: 5, left: 0}}
              >
                
                <Line
                  type="monotone"
                  dataKey="Bid"
                  yAxisId="left"
                  stroke="#f5b041"
                  strokeWidth={2}
                  dot={false}
                />
                <Line
                  type="monotone"
                  label="Ask"
                  dataKey="Ask"
                  yAxisId="left"
                  stroke="#095CE5"
                  strokeWidth={2}
                  dot={false}
                />
              
                <XAxis
                  dataKey="Date"
                  tickFormatter={tickItem =>
                    moment(tickItem).format('MM/DD/YY')
                  }
                  angle={295}
                  dy={30}
                  dx={-15}
                  height={80}
                />
                <YAxis
                  tickFormatter={tickItem => tickItem.toFixed(3)}
                  label={{
                    value: 'Bid / Ask',
                    angle: 270,
                    position: 'insideLeft',
                    offset: 10
                  }}
                  width={100}
                  yAxisId="left"
                  unit="%"
                />
                <Legend /> 
                <CartesianGrid stroke='#f5f5f5' vertical={false} /> 
                <Tooltip
                  formatter={(value, label) => {
                    if (label == "Ask") {
                      return [`${value}%`, `${label}`]
                    }
                    if (label == "Bid") {
                      return [`${value}%`, `${label}`]
                    }
                  }}
                  labelFormatter={date => moment(date).format('YYYY-MM-DD')}
                />
              </LineChart>
            </ResponsiveContainer>
            </div>

            </Col>
          <Col lg={6} className="text-section">
          <h2>Transaction History</h2>
          <p>Creditors have sold ${this.state.total} of claims through Claims Market in { this.state.count } transactions.</p>
            <SortTable />
          </Col>
        </Row>
        <Row>
          <Col md={12} className='reviews-container'>
          <iframe
            src='https://widgets.sociablekit.com/google-reviews/iframe/25393684'
            frameBorder='0'
            width='100%'
            height='320'></iframe>
            
          </Col>
        </Row>
      </div>
    </div>
    )
  }

  
}

function calculateTotalAmount(transactions) {
    const value = transactions.reduce((amount, transaction) => 
        amount += parseFloat(transaction.minAmount.substring(1).replace(/,/g, '')), 0);
    
    return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}


function normalizeData(data) {
    return data.map(el => {
        const info = el.bidAsk.map(d => ({
          Date: d['date'],
          Bid: parseFloat(d['bid']),
          Ask: parseFloat(d['ask'])
        }))
        return {
          name: el.name,
          data: info
        }
    })
}

function normalizeChartData(data) {

  return data.map(el => {
    const info = el.bidAsk.map(d => ({
      Date: d['date'],
      Bid: parseFloat(d['bid']),
      Ask: parseFloat(d['ask'])
    }))
    return {
      name: el.name,
      data: info
    }
  })
}

//export default SectionFtx
