import React from 'react'
import StepOne from './StepOne.js'
import StepTwo from './StepTwo.js'
import StepThree from './StepThree.js'
import StepFour from './StepFour.js'

import './styles/index.css'

export default class Steps extends React.Component {
  constructor() {
    super()
    this.state = {
      onboardingStep: 1
    }
    this.setOnboardingStep = this.setOnboardingStep.bind(this)
    this.getStepComponent = this.getStepComponent.bind(this)
  }

  setOnboardingStep(onboardingStep) {
    this.setState({
      onboardingStep: onboardingStep
    })
  }

  render() {
    return (
      <div id="steps" className="section-signup">
        {this.getStepComponent(this.state.onboardingStep)}
      </div>
    )
  }

  getStepComponent(step) {
    switch (step) {
      case 1:
        return <StepOne setOnboardingStep={this.setOnboardingStep} />
      case 2:
        return <StepTwo setOnboardingStep={this.setOnboardingStep} />
      case 3:
        return <StepThree setOnboardingStep={this.setOnboardingStep} />
      case 4:
        return <StepFour setOnboardingStep={this.setOnboardingStep} />
      default:
        return
    }
  }
}
