import React from 'react'
import {Image, Button, Row, Col} from 'react-bootstrap'
import './styles/SectionFive.css'

const SectionFive = () => {
  return (
    <div id="section-five-background">
      <div id="section-five" className="section">
        <Row>
          <Col md={7} className="text-section">
            <div>
              <h1>
                Seller's
                <br />
                Agreement
              </h1>
              <p>
                Sellers sign a Seller’s Agreement to offer their claims
                exclusively through Claims Market for a specified number of business
                days. Sellers send us an execution copy of the SAC (or a redline
                of requested changes to the SAC) together with relevant exhibits
                and copies of supporting documents.
              </p>
            </div>
          </Col>
          <Col md={5} className="image-section">
            <Image src="/assets/icons/sac-doc.svg" />
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-market-assets/sellersAgreement.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW SELLER'S AGREEMENT
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionFive
