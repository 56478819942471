import React from 'react'
import {Image, Button, Row, Col} from 'react-bootstrap'
import './styles/SectionFour.css'

const SectionFour = () => {
  return (
    <div id="section-four-background">
      <div id="section-four" className="section">
        <Row>
          <Col md={5} className="image-section">
            <Image src="/assets/icons/buyers-doc.svg" />
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-market-assets/buyersAgreement.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW BUYER'S AGREEMENT
              </Button>
            </a>
          </Col>
          <Col md={7} className="text-section">
            <Row>
              <Col md={12}>
                <h1>
                  Buyer's
                  <br />
                  Agreement
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h5>TRANSACTION PROCESS</h5>
                <p>
                  Once a transaction is confirmed by Claims Market, a SAC signed 
                  by the Seller with all exhibits will be sent to the winning 
                  Buyer identified as “Buyer” in the SAC.
                <br />
                  If funds are not received by the Seller and Claims Market from
                  the winning Buyer within two business days of the trade date,
                  the Seller’s claim will be offered to other Buyers.
                </p>
              </Col>
              <Col md={12}>
                <h5>ADMINISTRATIVE FEE</h5>
                <p>
                  Buyers agree to pay Claims Market an Admin. Fee stated on the
                  Trade Summary page with respect to each Seller’s claim.
                  Affiliates of Claims Market may bid on claims (after five
                  business days of listing) or offer claims for sale on the
                  site.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionFour
