import React from 'react'
import * as Scroll from 'react-scroll'
import './styles/SectionOne.css'
import axios from 'axios'
import history from '../../../history'

const animateScroll = Scroll.animateScroll

const checkLocation = (position) => {
  setTimeout(function() {
    var destination = document.querySelector('#' + position)
    const elementPosition = destination.offsetTop - 50
    animateScroll.scrollTo(elementPosition, {
      smooth: true,
      duration: 500
    })
  }, 200)
}

class SectionOne extends React.Component {

  state = {
    pricing: [],
    bid: '',
    ask: '',
    total: 0
  }

  componentDidMount() {  
    axios
        .get(`/api/asset-pricing`)
        .then(response => {
          const data = normalizeData(response.data)
          let ftx = data.find(o => o.name === 'FTX Trading Ltd.');
          // console.log(ftx);
          let latestPricing = ftx.data[ftx.data.length - 1];  
          this.setState({bid: latestPricing.Bid, ask: latestPricing.Ask, data: ftx.data})
      })
      axios.get('/api/transaction')
      .then(response => {
        let transactions = response.data;
        for (const i in transactions) {
          let transaction = transactions[i];		
          transaction.minAmount = 
            `$${Number(transaction.minAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`; 
        }
        this.setState({total: calculateTotalAmount(transactions)})
      });
  };


  render (){
    return (
    <React.Fragment>

    <div className="scene-video-container">
      <video autoPlay loop muted className="scene-video">
        <source src="/assets/media/hero_video.mp4" type="video/mp4" />
        <source src="/assets/media/hero_video.webm" type="video/webm" />
        {/* <source src='/assets/media/hero_video.ogv' type='video/ogg' /> */}
      </video>
    </div>
     <div className="voyager-scene-gradient"></div>
      <div className="scene-white-wedge"></div>
        <div className='section-one'>
          <div className="intro">
            <div className="title-container">
              <h1 className="title">FTX Claims</h1>
              <h3 className="subtitle">Claims Sold to date: ${this.state.total}. Please tap the button below to contact us. <br/><br/> Send us the following so we can accurately price your claim: a) email from ftx@noticing.ra.kroll.com with subject line: Your Scheduled Claim Information, and b) KYC Process screenshot from claims@ftx.com <br/><br/>We will respond quickly!</h3>

              <div className='crypto-contact'>
                <a
                      className="btn"
                      onClick={() =>
                        checkLocation('section-seven-background')
                      }
                    >Get In Touch</a>
              </div>
             
            </div>
          </div>
        </div>

     </React.Fragment>
  )
  }

  
}
function normalizeData(data) {
  return data.map(el => {
      const info = el.bidAsk.map(d => ({
      Date: d['date'],
      Bid: parseFloat(d['bid']),
      Ask: parseFloat(d['ask'])
      }))
      return {
      name: el.name,
      data: info
      }
  })
}
function calculateTotalAmount(transactions) {
  const value = transactions.reduce((amount, transaction) => 
      amount += parseFloat(transaction.minAmount.substring(1).replace(/,/g, '')), 0);
  
  return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export default SectionOne