import React from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import AdminUserItem from '../AdminUserItem'
import EditProfileForm from '../EditProfileForm'
import './UserPanelContent.css'
import {deleteAccount, editAccount, signUp} from '../../store'

class BuyerPanelContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddForm: false,
      name: '',
      title: '',
      company: '',
      phone: '',
      email: '',
      password: '',
      bankruptcyCase: '',
      claimAmount: '',
      askingPrice: '',
      accountType: '',
      approved: false
    }
    this.addItemClick = this.addItemClick.bind(this)
    this.onFormOptionChange = this.onFormOptionChange.bind(this)
    this.onFormValueChange = this.onFormValueChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  addItemClick() {
    this.setState(prevState => ({
      showAddForm: !prevState.showAddForm
    }))
  }

  onFormSubmit(event) {
    event.preventDefault()
    const value = {
      name: this.state.name,
      title: this.state.title,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      bankruptcyCase: this.state.bankruptcyCase,
      claimAmount: this.state.claimAmount,
      askingPrice: this.state.askingPrice,
      accountType: this.state.accountType,
      approved: this.state.approved
    }
    this.props.addItemFormSubmit(value)
  }

  onFormOptionChange(event) {
    this.setState({
      accountType: event.target.dataset.name
    })
  }

  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onCheckValueChange(event) {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  render() {
    const value = {
      name: this.state.name,
      title: this.state.title,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      bankruptcyCase: this.state.bankruptcyCase,
      claimAmount: this.state.claimAmount,
      askingPrice: this.state.askingPrice,
      accountType: this.state.accountType,
      approved: this.state.approved

    }
    return (
      <div id="panel-content">
        <React.Fragment>
          <Button
            className="btn-default addItemBtn"
            onClick={this.addItemClick}
          >{`Add User ${this.state.showAddForm ? '-' : '+'}`}</Button>
          {this.state.showAddForm && (
            <EditProfileForm
              title="ADD USER"
              value={value}
              onFormSubmit={this.onFormSubmit}
              onFormValueChange={this.onFormValueChange}
              onFormOptionChange={this.onFormOptionChange}
            />
          )}
        </React.Fragment>
        {this.props.buyers.map(item => {
          const payload = {
            id: item._id,
            name: item.name,
            title: item.title,
            company: item.company,
            email: item.email,
            phone: item.phone,
            password: item.password,
            accountType: item.accountType,
            agreementSigned: item.agreementSigned,
            canViewPricing: item.canViewPricing,
            isAdmin: item.isAdmin,
            bankruptcyCase: item.bankruptcyCase,
            claimAmount: item.claimAmount,
            askingPrice: item.askingPrice,
            approved: item.approved
          }
          return (
            <AdminUserItem
              key={item._id}
              info={payload}
              deleteItemSubmit={this.props.deleteItemSubmit}
              editItemFormSubmit={this.props.editItemFormSubmit}
              addItemFormSubmit={this.props.addItemFormSubmit}
            />
          )
        })}
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims,
    users: state.users,
    sellers: state.sellers,
    buyers: state.buyers
  }
}

const mapDispatch = dispatch => {
  return {
    deleteItemSubmit(userId) {
      dispatch(deleteAccount(userId))
    },
    addItemFormSubmit(form) {
      dispatch(signUp(form, true))
    },
    editItemFormSubmit(id, form) {
      dispatch(editAccount(id, form, true))
    }
  }
}

export default connect(mapState, mapDispatch)(BuyerPanelContent)
