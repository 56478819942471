import React from 'react'
import {connect} from 'react-redux'
import {Image, Button} from 'react-bootstrap'

import Header from '../OnboardingHeader'
import {logIn, getUser} from '../../store'
import ForgotPasswordModal from './ForgotPasswordModal'
import './SignIn.css'


class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      email: '',
      password: '',
      modalShow: false,
      loading: false
    }
    this.onFormValueChange = this.onFormValueChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.forgotPasswordModalToggle = this.forgotPasswordModalToggle.bind(this)
  }


  componentDidCatch(error, errorInfo){
    // console.log('stop');
    // console.log(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.account !== prevProps.account) {
      this.setLoading(false);
    }
  }

  setLoading = (setting) => {
    this.setState({loading: setting});
  }
 
  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onFormSubmit(event) {
    event.preventDefault()
    this.setLoading(true);
    this.props.submitSignInForm(this.state)
  }

  forgotPasswordModalToggle() {
    this.setState(prevState => ({modalShow: !prevState.modalShow}))
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div id="sign-in" className="container-fluid">
          <ForgotPasswordModal
            modalShow={this.state.modalShow}
            forgotPasswordModalToggle={this.forgotPasswordModalToggle}
          />
          <form
            className="signInForm card mx-auto"
            onSubmit={this.onFormSubmit}
          >
            <div className="card-header text-center">SIGN IN</div>
            <div className="card-body">

            {this.props.account.error && (
              <p className="top-error" style={{color: 'red'}}>{this.props.account.error.response.data}</p>
            )}
            {this.props.error && <p className="top-error" style={{color: 'red'}}>{props.error}</p>}

              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="input-container input-group">
                    <span className="input-group-text" id="email-input-signin">
                      <Image src="/assets/icons/flag.svg" />
                    </span>
                    <input
                      required
                      autoComplete="email"
                      aria-describedby="email-input-signin"
                      type="email"
                      name="email"
                      className="form-input form-control"
                      placeholder="EMAIL"
                      value={this.state.email}
                      onChange={this.onFormValueChange}
                    />
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="input-container input-group">
                    <span
                      className="input-group-text"
                      id="password-input-signin"
                    >
                      <Image src="/assets/icons/flag.svg" />
                    </span>
                    <input
                      required
                      autoComplete="off"
                      aria-describedby="password-input-signin"
                      type="password"
                      name="password"
                      className="form-input form-control"
                      placeholder="PASSWORD"
                      value={this.state.password}
                      onChange={this.onFormValueChange}
                    />
                  </div>
                </li>
                <li className="list-group-item sign-up-button">
                  <p>
                    Don't have an account?&nbsp;&nbsp;&nbsp;<a href="/signup">
                      Sign up now ›
                    </a>
                  </p>
                </li>
                <li className="list-group-item reset-password">
                  <p>
                    Forgot password?&nbsp;&nbsp;&nbsp;<button
                      className="btn btn-link"
                      type="button"
                      onClick={this.forgotPasswordModalToggle}
                    >
                      Reset password ›
                    </button>
                  </p>
                </li>
              </ul>
            </div>
            <div className="card-footer text-center">
              <Button type="submit" disabled={this.state.loading}>SUBMIT</Button>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    user: state.user
  }
}

const mapDispatch = dispatch => {
  return {
    submitSignInForm(userInfo) {
      dispatch(logIn(userInfo))
    }
  }
}

export default connect(mapState, mapDispatch)(SignIn)
