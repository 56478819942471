import React from 'react'
import {Image, Button, Row, Col, Container} from 'react-bootstrap'
import './styles/SectionSix.css'

const SectionSix = () => {
  return (
    <div id="section-six-background">
      <div id="section-six" className="section">
        <Row>
          <Col md={12} className="title-container">
            <h1>Legal</h1>
            <Image src="/assets/icons/legal.svg" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="description-container">
            <p>
              Claims Market is a service provided by Cherokee Hybrid Markets,
              Inc. Buyers acknowledge that Claims Market has entered into a
              Seller’s Agreement, or other agreement, with each Seller whose claims are offered for
              sale on the site granting Claims Market the exclusive right to
              sell their claims for approximately ten business days. Buyers agree not
              to circumvent or interfere with Claims Market’s rights under any
              Seller’s Agreement, or other agreement with any Seller to list their claim on the site. 
              Affiliates of Claims Market may bid on claims (after five business days of listing) or 
              offer claims for sale on the site.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionSix
