import { format } from 'date-fns';

export const COLUMNS = [    
    {
        Header: 'Sold',
        accessor: 'transactionDate',
        Cell: ({ value }) =>  { return format(new Date(value), 'LLL dd, yyyy')}

    },
    {
        Header: 'Listed',
        accessor: 'listDate',
        Cell: ({ value }) =>  { return format(new Date(value), 'LLL dd, yyyy')}

    },
    {
        Header: 'Lead Debtor',
        accessor: 'leadDebtor',
    },
    {
        Header: 'Minimum Claim Amount',
        accessor: 'minAmount',
        sortType: "custom" // here

    }
];