import React from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import AdminClaimItem from '../AdminClaimItem'
import AddClaimForm from '../AddClaimForm'
import {deleteClaim, postClaim, putClaimInfo} from '../../store'

import './ClaimPanelContent.css'

class ClaimPanelContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddForm: false,
      updateForm: false,
      init: true,
      claims: []
    }
    this.addItemClick = this.addItemClick.bind(this)
  }

  addItemClick() {
    this.setState(prevState => ({
      showAddForm: !prevState.showAddForm
    }))
  }

  componentDidUpdate() {
    if (this.props.claims !== this.state.claims) {
      if (this.state.init === false) {
        this.setState({claims: this.props.claims, updateForm: true})
      } else {
        this.setState({claims: this.props.claims, init: false})
      }
    }
  }

  render() {
    return (
      <div id="panel-content">
        <React.Fragment>
          <Button
            className="btn-default addItemBtn"
            onClick={this.addItemClick}
          >{`Add Claim ${this.state.showAddForm ? '-' : '+'}`}</Button>
          {this.state.showAddForm && (
            <AddClaimForm
              addItemFormSubmit={this.props.addItemFormSubmit}
              updateForm={this.state.updateForm}
            />
          )}
        </React.Fragment>
        {this.props.claims.map((item, i) => {
          const payload = {
            id: item._id,
            name: item.name,
            leadDebtor: item.leadDebtor,
            debtor: item.debtor,
            seller: item.seller,
            creditor: item.creditor,
            caseNumber: item.caseNumber,
            claimNumber: item.claimNumber,
            amount: item.amount,
            minAmount: item.minAmount,
            adminFee: item.adminFee,
            note: item.note,
            tradingStatus: item.tradingStatus,
            purchaseRate: item.purchaseRate,
            ext: item.ext,
            date: item.date,
            expireDate: item.expireDate
          }
          return (
            <AdminClaimItem
              key={i}
              info={payload}
              deleteItemSubmit={this.props.deleteItemSubmit}
              editItemFormSubmit={this.props.editItemFormSubmit}
              addItemFormSubmit={this.props.addItemFormSubmit}
            />
          )
        })}
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims,
    users: state.users
  }
}

const mapDispatch = dispatch => {
  return {
    deleteItemSubmit(id) {
      dispatch(deleteClaim(id))
    },
    addItemFormSubmit(form) {
      // console.log('claimpanelcontent 102');
      // console.log(form);
      dispatch(postClaim(form))
    },
    editItemFormSubmit(id, form) {
      dispatch(putClaimInfo(id, form))
    }
  }
}

export default connect(mapState, mapDispatch)(ClaimPanelContent)
