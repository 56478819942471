import axios from 'axios'

/**
/**ACTION TYPES
 */

const GET_ALL_BUYERS = 'GET_ALL_BUYERS'

/**
 * INITIAL STATE
 */
const defaultBuyers = []

/**
 * ACTION CREATORS
 */
export const getAllBuyers = buyers => ({type: GET_ALL_BUYERS, buyers})

/**
 * THUNK CREATORS
 */
export const fetchAllBuyers = () => async dispatch => {
  try {
    const res = await axios.get('/api/account/all-buyers')
    dispatch(getAllBuyers(res.data || defaultBuyers))
  } catch (err) {
    // do something
  }
}

/**
 * REDUCER
 */
export default function(state = defaultBuyers, action) {
  switch (action.type) {
    case GET_ALL_BUYERS:
      return action.buyers
    default:
      return state
  }
}
