import React from 'react'

import {Button, Row, Col} from 'react-bootstrap'

import {postContactUsEmail} from '../../../store/misc'

import ReCAPTCHA from 'react-google-recaptcha';

import './styles/SectionSeven.css'

class SectionSeven extends React.Component {
  constructor() {
    super()
    this.state = {
      name: '',
      email: '',
      message: '',
      bankruptcyCase: '',
      notification: '',
      notificationType: '',
      showNotification: false,
      recaptchaToken: '',
      
      subject: 'the Contact Us Form -'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleBankruptcyCaseChange = this.handleBankruptcyCaseChange.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.setRecaptchaToken = this.setRecaptchaToken.bind(this)
  }

  async handleSubmit(event) {
    event.preventDefault()
    const {email, name, bankruptcyCase, message, subject, recaptchaToken} = this.state
    let notification = ''
    if (this.state.name.length === 0) {
      notification = 'Must input name.'
    }
    if (this.state.email.length === 0) {
      notification = 'Must input an email.'
    }
    if (this.state.bankruptcyCase.length === 0) {
      notification = 'Must input bankruptcy case.'
    }
    if (this.state.message.length === 0) {
      notification = 'Must input a message.'
    }
    if (notification.length !== 0) {
      this.setState({
        showNotification: true,
        notificationType: 'warning',
        notification
      })
      return
    }

    let response = await postContactUsEmail(email, name, bankruptcyCase, message, subject, recaptchaToken)
    if (response.data && response.data.success === true) {
      notification = 'Thanks so much for the email!'
      this.setState({
        notification,
        notificationType: 'success',
        showNotification: true
      })
    } else {
      if(response.response.data){
        notification = response.response.data.message
      } else {
        notification = 'Something went wrong!'
      }
    
      this.setState({
        notification,
        notificationType: 'danger',
        showNotification: true
      })
    }
  }

  handleNameChange(event) {
    this.setState({
      name: event.target.value
    })
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value
    })
  }

  handleBankruptcyCaseChange(event) {
    this.setState({
      bankruptcyCase: event.target.value
    })
  }

  handleMessageChange(event) {
    this.setState({
      message: event.target.value
    })
  }

  setRecaptchaToken(event) {
    this.setState({
      recaptchaToken: event
    })
  }



  render() {
    return (
      <div id="section-seven-background">
        <div id="section-seven" className="section">
          <Row>
            <Col md={4} className="text-section">
              <h1>
                Contact
                <br />
                Us
              </h1>
              <p>
                Claims Market is a platform dedicated to simplifying the
                market for trade claims.
              </p>
              <p>
                We’re happy to help you with the process of listing your claims
                or answer any other questions you may have.
              </p>
              <p>
                1384 Broadway, Suite 906
                <br />
                New York, NY 10018
                <br />
                (212) 259 - 4300
              </p>
            </Col>
            <Col md={8} className="contact-form-container">
              <form className="contact-form" onSubmit={this.handleSubmit}>
                <input
                  required
                  autoComplete="name"
                  type="text"
                  name="name"
                  placeholder="Your name"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
                <input
                  required
                  autoComplete="email"
                  type="email"
                  name="email"
                  placeholder="Your email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
                <input
                  required
                  autoComplete=""
                  type="text"
                  name="bankruptcyCase"
                  placeholder="Bankruptcy Case"
                  value={this.state.bankruptcyCase}
                  onChange={this.handleBankruptcyCaseChange}
                />
                <textarea
                  required
                  autoComplete="off"
                  type="text"
                  name="message"
                  placeholder="Your message"
                  value={this.state.message}
                  onChange={this.handleMessageChange}
                />

                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <ReCAPTCHA
                    sitekey="6Ld3rl0qAAAAAJS3krtAA_VQopk7z-00UaSCLHG_"
                    onChange={this.setRecaptchaToken}
                  />
                </div>
                <a>
                  <Button type="submit" className="btn-default">
                    Send My Message
                  </Button>
                </a>
              </form>
              {this.state.showNotification && (
                <div
                  className={`alert alert-${this.state.notificationType}`}
                  role="alert"
                >
                  {this.state.notification}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default SectionSeven
