import React from 'react';

import './LowerBidModal.css'

export default function TradeConfirmModal(bidConfirmed, modalShow, selectedClaim) {
  let bidInput = React.createRef();
  let bidNote = React.createRef();

  let title = '';
  let sellerEngaged = false;
  if(selectedClaim.length > 0 && selectedClaim[0].tradingStatus == 'true'){
    title = "Seller Engaged";
    sellerEngaged = true;
  } else {
    title = 'Firm Bid at Lower Rate';
  }


  return (
    <div
      className="modal confirm-modal"
      tabIndex="-1"
      role="dialog"
      data-show={`${modalShow}`}
      data-focus={`${modalShow}`}
      style={{ display: modalShow ? 'block' : 'none'}}>

      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
          </div>

          <div className="modal-body">
          {sellerEngaged &&
                        <p>You are not able to bid while the Seller is engaged. See Bidding Protocol for details.</p>
          }

          {!sellerEngaged &&
            <div>
              <p>Enter Firm Bid at Lower Rate</p>
                <input 
                  className="quantity" 
                  name="quantity" 
                  value={bidInput.value} 
                  min="0.000"
                  step="0.001"
                  max="1.000"
                  type="number" 
                  ref={bidInput} />    
                  <div className="bidNote">
                    <p>State all conditions to your Firm Bid here (note: if your bid is accepted, you will not be able to introduce new conditions prior to closing)</p>
                    <textarea 
                      name='bidNote' 
                      value={bidNote.value}
                      ref={bidNote}
                      className='firmBidDesc form-control'></textarea>
                  </div>
              </div>      
          }

          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => bidConfirmed(false)}
            >
              Cancel
            </button>

            {!sellerEngaged &&

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => bidConfirmed(true, bidInput.current.value, bidNote.current.value)} // null
              >
                Bid
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
