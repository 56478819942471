import React from 'react'
import {Image, Row, Col, Button} from 'react-bootstrap'
import './styles/SectionTwo.css'

const SectionTwo = () => {
  return (
    <div id="section-two-background">

      <div id="section-two" className="section">
        <Row>
          <Col md={12} className="title-container">
            <h1>How it Works</h1>
            <h5>
              Our goal is to bring together Sellers and Buyers of claims
              <br />
              through a transparent site that reduces transaction costs.
            </h5>
            <p>
            <a>
              <Button
                onClick={() =>
                  window.open(
                    `https://www.youtube.com/watch?v=7oFIPnomWF8`,
                    '_blank'
                  )
                }
                className="btn btn-default orange-btn"
              >Explainer Video</Button>
            </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="description-container">
            <Image src="/assets/icons/doc.svg" />
            <h5>Sellers</h5>
            <p>
              Sellers can list their claims on the site, and upload necessary
              documents. Sellers can also review other Sellers' asking prices.
            </p>
          </Col>
          <Col md={4} className="description-container">
            <Image src="/assets/icons/clock.svg" />
            <h5>Save Time</h5>
            <p>
              We curate all claims offered, and only list scheduled or allowed
              claims which will be offered for sale on our site.
            </p>
          </Col>
          <Col md={4} className="description-container">
            <Image src="/assets/icons/dollar.svg" />
            <h5>Buyers</h5>
            <p>
              Buyers can scroll through the claims offered and select which
              claims they would like to bid for or purchase. All offered prices
              are executable.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionTwo
