import axios from 'axios'

/**
 * ACTION TYPES
 */
const GET_ALL_ASSET_PRICING = 'GET_ALL_ASSET_PRICING'

/**
 * INITIAL STATE
 */
const defaultAssetPricing = []

/**
 * ACTION CREATORS
 */
const getAllAssetPricing = assetPricing => ({type: GET_ALL_ASSET_PRICING, assetPricing})

/**
 * THUNK CREATORS
 */
export const fetchAllAssetPricing = () => async dispatch => {
  try {
    const res = await axios.get('/api/asset-pricing')
    console.log(res);
    dispatch(getAllAssetPricing(res.data || defaultAssetPricing))
  } catch (err) {
    // do something
  }
}

export const putAssetPricingInfo = (id, assetPricing) => async dispatch => {
  let res
  try {
    res = await axios.put(`/api/asset-pricing/${id}`, assetPricing)
  } catch (err) {
    // do something
  }

  try {
    res = await axios.get('/api/asset-pricing')
    dispatch(getAllAssetPricing(res.data || defaultAssetPricing))
  } catch (dispatchOrHistoryErr) {
    // do something
  }
}

/**
 * Posts a new assetPricing - occurs from the admin panel
 * 1. Posts assetPricing
 * 2. Stores document in a separate API call
 * 3. Gets all assetPricings
 * 4. Sends email to all users about the new assetPricing
 * 5. Stores assetPricing and assetPricings in redux store
 */
export const postAssetPricing = assetPricing => async dispatch => {
  let res

  try {
    res = await axios.post(`/api/asset-pricing`, assetPricing)
  } catch (err) {
    /* do something */
  }
  let id = res.data._id
  try {
    res = await axios.get('/api/asset-pricing')
  } catch (err) {
    /* do something */
  }
  const  assetPricing = res.data.assetPricing

  let file
  if (assetPricing.file &&  assetPricing.file[0]) {
    const formData = new FormData()
    formData.append('file',  assetPricing.file[0])
    try {
      file = await axios.post(`/api/asset-pricing/${id}/fileupdate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch (err) {
      // do something
      // // console.log(err)
    }
  }


  
}

export const deleteAssetPricing = assetPricingId => async dispatch => {
  try {
    await axios.delete(`/api/asset-pricing/${assetPricingId}`)
  } catch (err) {
    // do something
  }

  try {
    const res = await axios.get('/api/asset-pricing')
    dispatch(getAllAssetPricing(res.data || defaultAssetPricing))
  } catch (dispatchOrHistoryErr) {
    // do something
  }
}




export const listNewAssetPricing = data => async () => {
  try {
    await axios.post(`/api/asset-pricing/`, data)
  } catch (err) {
    // do something
  }
}

/**
 * REDUCER
 */
export default function(state = defaultAssetPricing, action) {
  switch (action.type) {
    case GET_ALL_ASSET_PRICING:
      return action.assetPricing
    default:
      return state
  }
}
