import React from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import axios from 'axios'
import Select from 'react-select'
import './AgreementPanelContent.css'

class AgreementPanelContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sacFile: '',
      redlineSacFile: '',
      buyersFile: '',
      sellersFile: '',
      inAppMessage: '',
      selectedSeller: '',
      selectedSPTASeller: '',
      sptaFile: '',
      pricingFile: false
    }
    this.handleSellerSelect = this.handleSellerSelect.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.clearInAppMessage = this.clearInAppMessage.bind(this)
    this.handlePricingUpload = this.handlePricingUpload.bind(this)

  }

  handleSellerSelect = selectedSeller => {
    this.setState({
      selectedSeller: selectedSeller
    })
  }

  handleSPTASellerSelect = selectedSeller => {
    this.setState({
      selectedSPTASeller: selectedSeller
    })
  }

  handleFileUpload = event => {
    this.setState({
      [event.target.name + 'File']: event.target.files
    })
  }

  handlePricingUpload = event => {
    this.setState({
      pricingFile: event.target.files,
    })   
  }

  clearInAppMessage = () => {
    setTimeout(() => {
      this.setState({
        inAppMessage: ''
      })
    }, 8000)
  }

  submitSacFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.sacFile[0])
    axios
      .post(`/api/file/sac`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'SAC is updated.'
        })
        this.sacInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sacInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitRedlineSacFile = event => {
    event.preventDefault()
    const sellerId = this.state.selectedSeller.value._id
    const formData = new FormData()
    formData.append('file', this.state.redlineSacFile[0])
    axios
      .post(`/api/file/redlineSac-${sellerId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'Red-line changes to SAC is updated.'
        })
        this.redlineSacInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sacInput.value = ''
        this.clearInAppMessage()
      })
  }

  componentDidMount() {
    this.fetchOptions()
  }

  fetchOptions = () => {
    axios
      .get(`/api/pricing`)
      .then(response => {
        const selectOptions = response.data.map(el => ({
          value: el.realKey,
          label: el.name
        }))
        this.setState({
          selectOptions,
          selected: selectOptions[0]
        })
      })
      .catch(error => {
        // console.log('error: ', error)
      })
  }

  submitBuyersFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.buyersFile[0])
    axios
      .post(`/api/file/buyersAgreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'Buyers Agreement is updated.'
        })
        this.buyersInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.buyersInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitSellersFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.sellersFile[0])
    axios
      .post(`/api/file/sellersAgreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'Sellers Agreement is updated'
        })
        this.sellersInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sellersInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitSPTAFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.sptaFile[0])
    axios
      .post(`/api/file/spta`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'SPTA is updated'
        })
        this.sptaInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sptaInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitRedlineSPTAFile = event => {
    event.preventDefault()
    const sellerId = this.state.selectedSPTASeller.value._id
    const formData = new FormData()
    formData.append('file', this.state.redlineSPTAFile[0])
    axios
      .post(`/api/file/redlineSPTA-${sellerId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'Red-line changes to SPTA is updated.'
        })
        this.redlineSacInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sacInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitPricingFile = event => {
    event.preventDefault()
    let file;
    try {
      file = this.state.pricingFile[0];
    } catch{
      file = false;
    }

    axios({
      method: 'post',
      url: `/api/pricing/${this.state.pricingFileName.replace(' ', '_')}`,

      data: {file},
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        this.setState({
          inAppMessage: 'The file is uploaded',
          pricingFileName: ''
        })
        this.pricingInput.value = ''
        this.clearInAppMessage()
        this.fetchOptions()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.pricingInput.value = ''
        this.clearInAppMessage()
      })
  }

  deletePricing = event => {
    event.preventDefault()
    axios
      .delete(`/api/pricing/${this.state.selected.label}`,{
        data: {
          name: this.state.selected.label,
          key: this.state.selected.value
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'The file is deleted'
        })
        this.clearInAppMessage()
        this.fetchOptions()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.clearInAppMessage()
      })
  }

  handlePricingNameChnage = event => {
    event.preventDefault()
    this.setState({
      pricingFileName: event.target.value
    })
  }

  render() {
    const sellers = this.props.users
      .filter(user => user.accountType === 'seller')
      .map(user => ({value: user, label: user.company}))
    const selectStyle = {
      container: provided => ({
        ...provided,
        width: 360,
        height: 45,
        margin: '0 auto'
      })
    }

    return (
      <div id="agree-panel-content">
        {this.state.inAppMessage && (
          <div className="inAppMessage">{this.state.inAppMessage}</div>
        )}
        <h6>SAC</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-market-assets/sac.pdf`,
              '_blank'
            )
          }
        >
          View SAC
        </Button>
        <form className="file-container" onSubmit={this.submitSacFile}>
          <input
            type="file"
            name="sac"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.sacInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>RED-LINE CHANGES TO SAC</h6>
        <p>Select Seller: </p>
        <Select
          styles={selectStyle}
          value={this.state.selectedSeller}
          onChange={this.handleSellerSelect}
          options={sellers}
        />
        {this.state.selectedSeller &&
        this.state.selectedSeller.value.hasRedlineSac ? (
          <Button
            className="btn-default"
            onClick={() =>
              window.open(
                `https://s3.us-east-2.amazonaws.com/claims-market-assets/redlineSac-${
                  this.state.selectedSeller.value._id
                }.${this.state.selectedSeller.value.redlineSacExt ? this.state.selectedSeller.value.redlineSacExt : 'pdf'}`,
                '_blank'
              )
            }
          >
            View red-line changes to SAC
          </Button>
        ) : (
          <Button className="btn-default">No red-line changes to SAC</Button>
        )}
        <form className="file-container" onSubmit={this.submitRedlineSacFile}>
          <input
            type="file"
            name="redlineSac"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.redlineSacInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>SPTA</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-market-assets/spta.pdf`,
              '_blank'
            )
          }
        >
          View SPTA
        </Button>
        <form className="file-container" onSubmit={this.submitSPTAFile}>
          <input
            type="file"
            name="spta"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.sptaInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>RED-LINE CHANGES TO SPTA</h6>
        <p>Select Seller: </p>
        <Select
          styles={selectStyle}
          value={this.state.selectedSPTASeller}
          onChange={this.handleSPTASellerSelect}
          options={sellers}
        />
        {this.state.selectedSPTASeller &&
        this.state.selectedSPTASeller.value.hasRedlineSPTA ? (
          <Button
            className="btn-default"
            onClick={() =>
              window.open(
                `https://s3.us-east-2.amazonaws.com/claims-market-assets/redlineSPTA-${
                  this.state.selectedSPTASeller.value._id
                }.${this.state.selectedSPTASeller.value.redlineSPTAExt ? this.state.selectedSPTASeller.value.redlineSPTAExt : 'pdf'}`,
                '_blank'
              )
            }
          >
            View red-line changes to SPTA
          </Button>
        ) : (
          <Button className="btn-default">No red-line changes to SPTA</Button>
        )}
        <form className="file-container" onSubmit={this.submitRedlineSPTAFile}>
          <input
            type="file"
            name="redlineSPTA"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.redlineSacInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>BUYERS AGREEMENT</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-market-assets/buyersAgreement.pdf`,
              '_blank'
            )
          }
        >
          View Buyers Agreement
        </Button>
        <form className="file-container" onSubmit={this.submitBuyersFile}>
          <input
            type="file"
            name="buyers"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.buyersInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>SELLERS AGREEMENT</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-market-assets/sellersAgreement.pdf`,
              '_blank'
            )
          }
        >
          View Sellers Agreement
        </Button>
        <form className="file-container" onSubmit={this.submitSellersFile}>
          <input
            type="file"
            name="sellers"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.sellersInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>

        <h6>PRICING</h6>
        <p className="subtitle">Select File to Delete</p>
        <Select
          options={this.state.selectOptions}
          onChange={option => {

            this.setState({selected: option})
          }}
          value={this.state.selected}
          className="dropdown"
        />
        <button
          type="submit"
          className="delete-btn"
          onClick={this.deletePricing}
        >
          Delete
        </button>
        <p className="subtitle text-center">Upload New File<br/><small>Make sure there is only one period before xlsx. Ex: file-name.xlsx</small></p>
        <form className="file-container" onSubmit={this.submitPricingFile}>
          <input
            type="text"
            name="fileName"
            placeholder="File Name"
            value={this.state.pricingFileName}
            onChange={this.handlePricingNameChnage}
          />
          <input
            type="file"
            name="pricing"
            onChange={this.handlePricingUpload}
            ref={ref => {
              this.pricingInput = ref
            }}
          />
          <button type="submit">Upload</button>
        </form>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims,
    users: state.users
  }
}

const mapDispatch = dispatch => {
  return {}
}

export default connect(mapState, mapDispatch)(AgreementPanelContent)
