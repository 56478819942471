import axios from 'axios'

export const postCryptoContactUsEmail = async (email, name, company, phone, claimAmount, message, subject, accountStatement, bidAsk, recaptchaToken) => {
  let file;
  try {
    file = accountStatement[0];
  } catch{
    file = false;
  }
  let jsonBidAsk = JSON.stringify(bidAsk);
 
  try {
    const response = await axios({
      method: 'post',
      url: '/api/contact-us-crypto', 
      data: {email, name, company, phone, claimAmount, message, subject, file, jsonBidAsk, recaptchaToken},
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response;
  } catch(error){
    // console.log(error);
    return error;
  }
 
  
  
}
