import React from 'react'
import './ClaimItem.css'

const ClaimItem = props => {
  return (
    <tr>
      <td scope="row">{props.num}</td>
      <td scope="col">{props.item.debtor}</td>
      <td scrope="col">{props.item.creditor}</td>
      <td colSpan="col">
        <a
          id="claim-btn"
          className="badge badge-primary"
          href={`https://s3.us-east-2.amazonaws.com/claims-market-assets/claims/${
            props.item._id
          }.${props.item.ext ? props.item.ext : 'pdf'}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          VIEW
        </a>
      </td>
    </tr>
  )
}

export default ClaimItem
