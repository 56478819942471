import React from 'react'
import {Button, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import './styles/CDS.css'

const CDS = () => {
  return (
    <div id="cds-background">
      <div id="cds" className="section">
        <Row className="text-section">
          <Row>
            <Col md={12}>
              <h1>Prices</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>
                Prices displayed on the charts below represent indicative "bid" and "ask" historical prices for larger claims of the referenced Debtor entities. Please note that prices of actual transactions may be higher or lower. Future prices may change based on developments in each bankruptcy case and changes in financial market conditions. Feel free to contact us at <a href="mailto:all@cherokeeacq.com">all@cherokeeacq.com</a> for current prices.
              </p>
            </Col>
          </Row>
        </Row>
        <Row className="chart-link">
          <Link to="/pricing">
            <Button className="btn-default">View Pricing</Button>
          </Link>
        </Row>
      </div>
    </div>
  )
}

export default CDS
