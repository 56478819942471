import React, {Component} from 'react'

import './FAQ.css'

export default class FAQ extends Component {
  render() {
    return (
      <div id="faq">
        <div className="faq-page container">
          <div className="row page-lead">
            <div className="col-12 col-sm-10 mx-auto">
              <h1 className="display-3">FAQ</h1>
              <p className="lead">
                What are the advantages to listing my claim on Claims Market, as
                opposed to selling to a claim Buyer who has contacted me?
              </p>
            </div>
          </div>
          <div className="row list-section">
            <div className="col-12 col-sm-10 mx-auto">
              <ol>
                <li>
                  <p className="pt-0">
                    Better pricing. By listing your claim on Claims Market, you
                    are more likely to get the best price.
                  </p>
                  <ul>
                    <li>
                      More Buyers will see your claim for sale. Buyers who could
                      not find you before, can now see your claim. Claims Market
                      brings out more Buyers and shifts the demand/supply
                      balance in your favor.
                    </li>
                    <li>
                      More competitive markets. The transparency of the market
                      will encourage competition between Buyers and push prices
                      higher.
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    Simple documents. You see everything upfront that you need
                    to close.
                  </p>
                  <ul>
                    <li>
                      If you use Claims Market's Simple Assignment of Claim
                      (“SAC”), there is no need to negotiate legal terms. This
                      saves you time and money.
                    </li>
                    <li>
                      If you choose to make specific changes to the SAC,
                      negotiating time and money is minimized since the Buyer
                      only needs to focus on the Seller's specific red-lined
                      changes to the SAC.
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    Fast closing. The closing process is short and predictable.
                  </p>
                  <ul>
                    <li>
                      Once a Buyer has confirmed their agreement to purchase
                      your claim, they are obligated to pay you, by wire
                      transfer of funds within <u>two business days</u>.
                    </li>
                    <li>
                      The fast closing process has been agreed to by Buyers in
                      advance when they sign the Buyer's Agreement.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
