import React from 'react'
import {connect} from 'react-redux'
import {Row, Col} from 'react-bootstrap'

import Header from '../OnboardingHeader'
import ClaimPanelContent from '../ClaimPanelContent'
import UserPanelContent from '../UserPanelContent'
import SellerPanelContent from '../SellerPanelContent'
import BuyerPanelContent from '../BuyerPanelContent'
import AgreementPanelContent from '../AgreementPanelContent'
import TransactionsPanelContent from '../TransactionsPanelContent/TransactionsPanelContent'
import AssetPricingPanelContent from '../AssetPricingPanelContent/AssetPricingPanelContent'

import {fetchAllUsers, fetchAllSellers, fetchAllBuyers} from '../../store'

import './AdminPanel.css'

class AdminPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'claims'
    }
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  handleTabChange(event) {
    this.setState({
      tab: event.target.dataset.name
    })
  }

  componentDidMount() {
    this.props.initAllUsers()
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Row id="admin-panel">
          <Col md={2} />
          <Col md={8} className="panel-container">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    this.state.tab === 'claims' ? 'active' : ''
                  }`}
                  data-name="claims"
                  onClick={this.handleTabChange}
                >
                  CLAIMS
                </a>
              </li>
            
              <li className="nav-item">
                <a
                  data-name="sellers"
                  className={`nav-link ${
                    this.state.tab === 'sellers' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  SELLERS
                </a>
              </li>
              <li className="nav-item">
                <a
                  data-name="buyers"
                  className={`nav-link ${
                    this.state.tab === 'buyers' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  BUYERS
                </a>
              </li>
              <li className="nav-item">
                <a
                  data-name="agreements"
                  className={`nav-link ${
                    this.state.tab === 'agreements' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  AGREEMENTS
                </a>
              </li>
              <li className="nav-item">
                <a
                  data-name="transactions"
                  className={`nav-link ${
                    this.state.tab === 'transactions' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  TRANSACTIONS
                </a>
              </li>
              <li className="nav-item">
                <a
                  data-name="assetPricing"
                  className={`nav-link ${
                    this.state.tab === 'assetPricing' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  PRICING
                </a>
              </li>
            </ul>
            {this.getContent()}
          </Col>
          <Col md={2} />
        </Row>
      </React.Fragment>
    )
  }

  getContent() {
    switch (this.state.tab) {
      case 'claims':
        return <ClaimPanelContent />
      case 'users':
        return <UserPanelContent />
      case 'sellers':
        return <SellerPanelContent />
      case 'buyers':
        return <BuyerPanelContent />
      case 'agreements':
        return <AgreementPanelContent />
      case 'transactions':
        return <TransactionsPanelContent />
      case 'assetPricing':
        return <AssetPricingPanelContent />
      default:
        return ''
    }
  }
}

const mapState = state => {
  return {
    users: state.user
  }
}

const mapDispatch = dispatch => {
  return {
    initAllUsers() {
      dispatch(fetchAllUsers())
      dispatch(fetchAllSellers())
      dispatch(fetchAllBuyers())
    }
  }
}

export default connect(mapState, mapDispatch)(AdminPanel)
