import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import * as Scroll from 'react-scroll'

import './TradeConfirm.css'

const animateScroll = Scroll.animateScroll

class TradeConfirm extends Component {
  componentDidMount() {
    animateScroll.scrollTo(0, {smooth: true, duration: 500})
  }

  render() {
    const claims = this.props.selectedClaims
    const minAmount =
      claims.reduce((acc, cur) => acc + Number(cur.minAmount), 0) /
      claims.length
    const consideration =
      claims.reduce(
        (acc, cur) => acc + Number(cur.minAmount) * Number(cur.purchaseRate),
        0
      ) / claims.length
    const purchaseRate = (consideration / minAmount * 100).toLocaleString(
      undefined,
      {minimumFractionDigits: 2, maximumFractionDigits: 2}
    )
    const adminFee = (
      claims.reduce((acc, cur) => acc + Number(cur.adminFee), 0) / claims.length
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return (
      <div id="trade-confirm">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="title text-center">
                Thanks for the order! You will also receive an Order Summary by
                email.
              </p>
            </div>
          </div>
          <div className="form-section card mx-auto">
            <div className="card-header text-center">Order Confirmation</div>
            <div className="card-body mx-auto">
              <p>You have placed a firm order to buy the following:</p>
              <table>
                <tbody>
                  <tr>
                    <th className="grey">Lead Debtor</th>
                    <th colSpan="2">{claims[0].leadDebtor}</th>
                  </tr>
                  <tr style={{borderBottom: '2px solid #a1a1a1'}}>
                    <th className="grey">Seller</th>
                    <th colSpan="2">{claims[0].creditor}</th>
                  </tr>
                  <tr>
                    <th className="grey">Minimum Claim Amount</th>
                    <td>
                      $
                      {minAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="grey">Purchase Rate</th>
                    <td>{purchaseRate}%</td>
                  </tr>
                  <tr>
                    <th className="grey">Consideration</th>
                    <td>
                      $
                      {consideration.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="grey">Admin. Fee</th>
                    <td>${adminFee}</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Please note that if the Administrator confirms
                that your buy order has been executed, then within
                two (2) business days of today’s trade date, you are
                required to pay: a) the Consideration to the Seller,
                and b) the Admin. Fee to us.
              </p>
            </div>
            <div className="card-footer text-center">
              <Link to="/">
                <Button type="submit" className="btn-default">
                  HOME
                </Button>
              </Link>
              <Link to="/profile">
                <Button type="submit" className="btn-default">
                  PROFILE
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims,
    selectedLeadDebtor: state.selectedLeadDebtor,
    selectedSeller: state.selectedSeller
  }
}

export default connect(mapState)(TradeConfirm)
