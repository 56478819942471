import React, { useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { COLUMNS } from '../../Transactions/Columns';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';

const SortTable = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const Columns = COLUMNS;

	const getData = async () => {

		axios.get('/api/transaction')
			.then(response => {
				const transactions = response.data;
				let list = [];
				for (const i in transactions) {
					transactions[i].minAmount = 
						`$${Number(transactions[i].minAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
					//if(transactions[i].leadDebtor == 'FTX Trading Ltd.'){
						list.push(transactions[i]);
					//}
					
				}
				setData(list);
				setLoading(false);
			});
	}

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ 
			columns: Columns, 
			data: data,
			initialState: {
				sortBy: [{ id: "transactionDate", desc: true }]
			},    
			// Here --------
			sortTypes: {
				custom: (rowA, rowB, columnId) => {
				  	let a = rowA.values[columnId];
				 	let b = rowB.values[columnId];
					a = Number(a.replace(/(^\$|,)/g,''));
					b = Number(b.replace(/(^\$|,)/g,''));
					if (!isNaN(a) && !isNaN(b) ){
						return Number(b) - Number(a);
					}
				  	return a.localeCompare(b);
				}
			  },
			  // -------------
		}, useSortBy);


	
	useEffect(() => {
		setLoading(true);
		getData();

	}, []);
		

	return (
		<React.Fragment>
			<Row>
				<Col md={12}>
					<div className="table-responsive">
						<table {...getTableProps()} className="table table-dark">
							<thead className="thead-light">
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column, index) => (
											<th {...column.getHeaderProps(column.getSortByToggleProps())}>
												{column.render('Header')}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.map((row) => {
									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												if(cell.column.Header == 'Minimum Claim Amount'){
													return (
														<td {...cell.getCellProps()} className='text-right'>{cell.render('Cell')}</td>
													);
												} else {
													return (
														<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
													);
												}												
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default SortTable;