import React from 'react';
import { connect } from 'react-redux';
import * as Scroll from 'react-scroll';
const moment = require('moment')

import {
  Image, 
  Button,
} from 'react-bootstrap';

import { 
  confirmClaim, 
  confirmBidClaim 

} from '../../../store';

import LowerBidModal from './LowerBidModal';
import TradeConfirmModal from './TradeConfirmModal';

import './ConfirmClaim.css'

const animateScroll = Scroll.animateScroll

class ConfirmClaim extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedClaims: [],
      modalShow: false,
      modalBidShow: false,
      confirmed: false,
      confirmedBid: false,
      bidAmount: null,
      bidNote: null,
      flash: null
    };

    this.onBidClick = this.onBidClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.bidConfirmed = this.bidConfirmed.bind(this);
    this.changeConfirmed = this.changeConfirmed.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedClaims: this.props.selectedClaims
    });

    animateScroll.scrollTo(0, {
      smooth: true, duration: 500
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedClaims !== this.props.selectedClaims) {
      this.setState({
        selectedClaims: this.props.selectedClaims
      });
    }

    const selectedSeller = this.props.selectedSeller;
    const seller = this.props.users.find(
      user => user.company === selectedSeller
    );

    const useSAC = 
      (seller && seller.hasRedlineSac) ? false : true;

    const useSPTA = 
      (seller && seller.hasRedlineSPTA) ? false : true;

    if ((prevState.confirmed !== this.state.confirmed) && (this.state.confirmed === true)) {

      this.props.onConfirmClick(
        this.state.selectedClaims,
        this.props.account,
        useSAC,
        useSPTA
      );

      this.props.setCreateClaimStep(3, this.state.selectedClaims);
      return;
    }
  }

  onBidClick() {
    this.setState({ modalBidShow: true });
  }

  onConfirmClick() {
    this.setState({ modalShow: true });
  }

  changeConfirmed(confirmed, bidConfirmed) {
    if (confirmed && bidConfirmed) {
      this.setState({ 
        modalShow: false,
        confirmedBid: false,
        flash: 'Bid Placed'

      });
      const selectedSeller = this.props.selectedSeller;
      const seller = this.props.users.find(
        user => user.company === selectedSeller
      );
  
      const useSAC = 
        (seller && seller.hasRedlineSac) ? false : true;
  
      const useSPTA = 
        (seller && seller.hasRedlineSPTA) ? false : true;
      this.props.onConfirmBidClick(
        this.state.selectedClaims,
        this.props.account,
        useSAC,
        useSPTA,
        this.state.bidAmount,
        this.state.bidNote
      );
    } else {
      this.setState({ 
        confirmed, 
        modalShow: false,
        confirmedBid: false
      });
    }
  }

  bidConfirmed(bid, amount, note) {

    if (bid) {
      this.setState({ 
        bidAmount: amount,
        bidNote: note,
        modalBidShow: false,
        modalShow: true,
        confirmedBid: true,
      });

    } else {
      this.setState({ 
        bidAmount: 0, 
        modalBidShow: false,
        modalShow: false,
        confirmedBid: false,
      });
    }
  }

  // eslint-disable-next-line complexity
  render() {
    let flash = this.state.flash;
    const selectedClaims = this.state.selectedClaims;
    const minAmount =
      selectedClaims.reduce((acc, cur) => acc + Number(cur.minAmount), 0) /
      selectedClaims.length;

    const consideration =
      selectedClaims.reduce(
        (acc, cur) => acc + Number(cur.minAmount) * Number(cur.purchaseRate),
        0
      ) / selectedClaims.length;

    const purchaseRate = consideration / minAmount;
    const adminFee =
      selectedClaims.reduce((acc, cur) => acc + Number(cur.adminFee), 0) /
      selectedClaims.length;

    const selectedSeller = this.props.selectedSeller;
    const seller = this.props.users.find(
      user => user.company === selectedSeller
    );

    let sptaSeller;
    if (selectedClaims.length > 0) {
      sptaSeller = selectedClaims[0].seller;
    }

    let expireDate;
    if(selectedClaims.length > 0 && selectedClaims[0].expireDate){
      expireDate = selectedClaims[0].expireDate;
      expireDate = moment.utc(expireDate).format('LL');
    }

    let note = null;
    if(selectedClaims.length > 0 && selectedClaims[0].note){
      note = selectedClaims[0].note
    }

    let tradingStatus = null;
    if(selectedClaims.length > 0 && selectedClaims[0].tradingStatus){
      tradingStatus = selectedClaims[0].tradingStatus
    }

    return (
  
      <div id="confirm-claim">
        {TradeConfirmModal(this.changeConfirmed, this.state.confirmedBid, this.state.modalShow, this.state.selectedClaims)}
        {LowerBidModal(this.bidConfirmed, this.state.modalBidShow, this.state.selectedClaims)}

        <div>
          <div className="row">
            <div className="col">
              <div className="image-section mx-auto">
                <p className="text-uppercase">
                  {this.props.selectedLeadDebtor}
                </p>

                <Image src="/assets/icons/arrow-right.svg" />
                <p className="text-uppercase">{this.props.selectedSeller}</p>
              </div>
            </div>
          </div>

          <div className="form-section card mx-auto">
            <div className="card-header text-center">TRADE SUMMARY</div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th scope="col">Debtor</th>
                      <td scope="col">{this.props.selectedClaims[0].debtor}</td>
                    </tr>

                    <tr>
                      <th scope="col">Seller</th>
                      <td scope="col">{selectedSeller}</td>
                    </tr>

                    {(sptaSeller && sptaSeller.length > 0) ? (
                      <tr>
                      <th scope="col">Original Creditor</th>
                      <td scope="col">{sptaSeller}</td>
                    </tr>

                    ) : null }

                    {!(sptaSeller && sptaSeller.length > 0) ? (
                      <tr>
                        <th scope="col">
                            Changes to SAC
                        </th>

                        <td scope="col">
                          <div className="sac-changes-row">
                            <div className="option-container">
                              <div className="option">
                                 {(seller && seller.hasRedlineSac) ? (
                                  <div className="sac-changes-row">
                                    <p>Yes</p>
                                    <div className="link-container">
                                        <p
                                          onClick={() =>
                                            window.open(
                                              `https://s3.us-east-2.amazonaws.com/claims-market-assets/redlineSac-${
                                                seller._id
                                              }.${
                                                seller.redlineSacExt
                                                  ? seller.redlineSacExt
                                                  : 'pdf'
                                              }`,
                                              '_blank'
                                            )
                                          }>
                                          View red-line changes to SAC
                                        </p>
                                      </div>
                                  </div>
                                 ) : (
                                  <p>No</p>
                                 )} 
                              </div>
                            </div>
                           
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="col">
                            Changes to SPTA:
                          <div className="option-container">
                            <div className="option">
                              <div
                                className={
                                  !(seller && seller.hasRedlineSPTA)
                                    ? 'btn-radio-checked'
                                    : 'btn-radio'
                                } />

                              <p>No</p>
                            </div>

                            <div className="option option-right">
                              <div
                                className={
                                  seller && seller.hasRedlineSPTA
                                    ? 'btn-radio-checked'
                                    : 'btn-radio'
                                } />

                              <p>Yes</p>
                            </div>
                          </div>
                        </th>

                        <td scope="col">
                          <div className="link-container">
                            <Image src="/assets/icons/doc.svg" />
                            <p
                              onClick={() =>
                                window.open(
                                  `https://s3.us-east-2.amazonaws.com/claims-market-assets/spta.pdf`,
                                  '_blank'
                                )
                              }>
                              View SPTA
                            </p>
                          </div>

                          {seller && seller.hasRedlineSPTA ? (
                            <li className="list-group-item">
                              <div className="link-container">
                                <p
                                  onClick={() =>
                                    window.open(
                                      `https://s3.us-east-2.amazonaws.com/claims-market-assets/redlineSPTA-${
                                        seller._id
                                      }.${
                                        seller.redlineSPTAExt
                                          ? seller.redlineSPTAExt
                                          : 'pdf'
                                      }`,
                                      '_blank'
                                    )
                                  }>
                                  View red-line changes to SPTA
                                </p>
                              </div>
                            </li>

                          ) : null}
                        </td>
                      </tr>
                    )}
                    
                    <tr>
                      <th scope="col">Minimum Claim Amount</th>
                      <td scope="col">
                        $
                        {minAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col">Purchase Rate</th>
                      <td scope="col">
                        {(purchaseRate * 100).toLocaleString(undefined, {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3
                        })}
                        %
                      </td>
                    </tr>

                    <tr>
                      <th scope="col">Consideration</th>
                      <td scope="col">
                        $
                        {consideration.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                        {/* <Button id="btn-bid" onClick={() => console.log('show bid input')} >Bid</Button> */}
                      </td>
                    </tr>


                    {(expireDate) ? (
                      <tr>
                        <th scope="col">Listing Expiration</th>
                        <td scope="col">
                          { expireDate }
                        </td>
                      </tr>

                    ) : null }

                    <tr>
                      <th scope="col">Admin. Fee</th>
                      <td scope="col">
                        $
                        {adminFee.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    </tr>


                    {(note) ? (
                      <tr>
                        <th scope="col">Note</th>
                        <td scope="col">
                          { note}
                        </td>
                      </tr>

                    ) : null }


                    {(tradingStatus == "true") ? (
                      <tr>
                        <th scope="col">Trading Status</th>
                        <td scope="col">
                        {tradingStatus == 'true' ? 'Seller engaged with Buyer' : ''}
                        
                        </td>
                      </tr>

                    ) : null }

                   
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-footer text-center">
                {(flash != null) ? (
                  <div className='alert alert-success'>
                    <p className='text-success'>{flash}</p>
                  </div>
                ) : null }

              <Button
                type="submit"
                className="btn-default"
                disabled={this.props.account.accountType === 'seller'}
                onClick={() => this.onConfirmClick()}
              >
                FIRM BID AT PURCHASE RATE
              </Button>

              <Button
                type="submit"
                className="btn-secondary"
                style={{ marginLeft: 5, position: 'absolute', right: '10px', background: 'rgb(150,150,150)' }}
                disabled={this.props.account.accountType === 'seller'}
                onClick={() => this.onBidClick()}
              >
                FIRM BID AT LOWER RATE
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    selectedLeadDebtor: state.selectedLeadDebtor,
    selectedSeller: state.selectedSeller,
    users: state.users
  };
};

const mapDispatch = dispatch => {
  return {
    onConfirmClick(claims, account, useSAC, useSPTA) {
      dispatch(confirmClaim({
        account,
        claims,
        useSAC,
        useSPTA
      }))
    },
    onConfirmBidClick(claims, account, useSAC, useSPTA, bidAmount, bidNote) {
      dispatch(confirmBidClaim({
        account,
        claims,
        useSAC,
        useSPTA,
        bidAmount,
        bidNote
      }))
    }
  };
};

export default connect(mapState, mapDispatch)(ConfirmClaim);
