import React, {Component} from 'react'
import InputGroup from '../InputGroup/InputGroup'

export default class NewTransaction extends Component {
  state = {
    selected: false,
    transaction: {
      transactionDate: '',
      debtor: '',
      minAmount: '',
      listDate: '',
      name: ''
    }
  }

  onChange = (ev, name) => {
    const value = ev.target.value
    this.setState(prevState => {
      const transaction = prevState.transaction
      transaction[name] = value
      return prevState
    })
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <button
            className="btn btn-link"
            type="button"
            data-toggle="collapse"
            onClick={() =>
              this.setState(prevState => ({selected: !prevState.selected}))
            }
          >
            Post New Transaction
          </button>
        </div>

        <div className={`collapse ${this.state.selected ? 'show' : ''}`}>
          <div className="card-body">
            {Object.keys(this.state.transaction).map(name => (
              <InputGroup
                key={name}
                name={name}
                value={this.state.transaction[name]}
                onChange={this.onChange}
                type={
                  name.match(new RegExp('transactionDate|listDate'))
                    ? 'date'
                    : name === 'minAmount' && 'number'
                }
              />
            ))}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.props.post(this.state.transaction)
                this.setState({
                  transaction: {
                    transactionDate: '',
                    debtor: '',
                    minAmount: '',
                    listDate: '',
                    name: ''
                  }
                })
              }}
              disabled={
                !this.state.transaction.transactionDate ||
                !this.state.transaction.debtor ||
                !this.state.transaction.minAmount ||
                !this.state.transaction.listDate ||
                !this.state.transaction.name
              }
            >
              Post
            </button>
          </div>
        </div>
      </div>
    )
  }
}
