import React from 'react'
import Header from '../Header'

class Reviews extends React.Component {

  render() {
    return (
      <div>
        <Header {...this.props} />
       <div>
          <div className="reviews-wrapper">
            <iframe src="https://widget.tagembed.com/52858?view"></iframe>
          </div>
       </div>
      </div>

    )
  }
}

export default Reviews
