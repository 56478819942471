import React from 'react'
import {connect} from 'react-redux'
import {Image, Button} from 'react-bootstrap'
import './EditProfileForm.css'

// eslint-disable-next-line complexity
const EditProfileForm = props => {
  let requiredIfSignup = false
  if (props.title === 'STEP ONE: SIGN UP') {
    requiredIfSignup = true
  }
  return (
    <form
      id="editProfileForm"
      className="card mx-auto"
      onSubmit={props.onFormSubmit}
    >
      <div className="card-header">{props.title}</div>
      {props.account.error && (
        <p className="top-error" style={{color: 'red'}}>{props.account.error.response.data}</p>
      )}
      {props.error && <p className="top-error" style={{color: 'red'}}>{props.error}</p>}
      <div className="card-body">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="name-input">
                  <Image src="assets/icons/flag.svg" />
                </span>
              </div>
              <input
                required={requiredIfSignup}
                autoComplete="name"
                aria-describedby="name-input"
                type="text"
                name="name"
                className="form-input form-control"
                placeholder="NAME"
                value={props.value.name}
                onChange={props.onFormValueChange}
              />
            </div>
          </li>
          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="name-input">
                  <Image src="assets/icons/flag.svg" />
                </span>
              </div>
              <input
                autoComplete="name"
                aria-describedby="name-input"
                type="text"
                name="title"
                className="form-input form-control"
                placeholder="TITLE"
                value={props.value.title}
                onChange={props.onFormValueChange}
              />
            </div>
          </li>
          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="company-input">
                  <Image src="/assets/icons/flag.svg" />
                </span>
              </div>
              <input
                autoComplete="org"
                aria-describedby="company-input"
                type="text"
                name="company"
                className="form-input form-control"
                placeholder="COMPANY"
                value={props.value.company}
                onChange={props.onFormValueChange}
              />
            </div>
          </li>
          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="phone-input">
                  <Image src="/assets/icons/flag.svg" />
                </span>
              </div>
              <input
                autoComplete="tel"
                aria-describedby="phone-input"
                type="tel"
                name="phone"
                className="form-input form-control"
                placeholder="PHONE"
                value={props.value.phone}
                onChange={props.onFormValueChange}
              />
            </div>
          </li>
          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="email-input">
                  <Image src="/assets/icons/flag.svg" />
                </span>
              </div>
              <input
                required={requiredIfSignup}
                autoComplete="email"
                aria-describedby="email-input"
                type="email"
                name="email"
                className="form-input form-control"
                placeholder="EMAIL"
                value={props.value.email}
                onChange={props.onFormValueChange}
              />
            </div>
          </li>
          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="name-input">
                  <Image src="/assets/icons/flag.svg" />
                </span>
              </div>
   
              <select 
                required={requiredIfSignup}
                name="bankruptcyCase"
                className="form-input form-control"
                placeholder="BANKRUPTCY CASE"
                value={props.value.bankruptcyCase}
                onChange={props.onFormValueChange}
              >
                <option>BANKRUPTCY CASE</option>
                <option>FTX</option>
                <option>Celsius</option>
                <option>BlockFi</option>
                <option>SVB</option>
                <option>Genesis</option>
                <option>Other</option>
              </select>
            </div>
          </li>
          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="name-input">
                  <Image src="/assets/icons/flag.svg" />
                </span>
              </div>
              <input
                required={requiredIfSignup}
                autoComplete="name"
                aria-describedby="name-input"
                type="text"
                name="claimAmount"
                className="form-input form-control"
                placeholder="CLAIM AMOUNT"
                value={props.value.claimAmount}
                onChange={props.onFormValueChange}
              />
            </div>
          </li>

          <li className="list-group-item">
            <div className="input-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="name-input">
                  <Image src="/assets/icons/flag.svg" />
                </span>
              </div>
              <input
                autoComplete="name"
                aria-describedby="name-input"
                type="text"
                name="askingPrice"
                className="form-input form-control"
                placeholder="ASKING PRICE"
                value={props.value.askingPrice}
                onChange={props.onFormValueChange}
              />
            </div>
          </li>

          {requiredIfSignup && (
            <li className="list-group-item">
              <div className="input-container input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="password-input">
                    <Image src="/assets/icons/flag.svg" />
                  </span>
                </div>
                <input
                  required={requiredIfSignup}
                  autoComplete="off"
                  aria-describedby="password-input"
                  type="password"
                  name="password"
                  className="form-input form-control"
                  placeholder="PASSWORD"
                  value={props.value.password}
                  onChange={props.onFormValueChange}
                />
              </div>
            </li>
          )}
          {!requiredIfSignup && (
            <li className="list-group-item">
              <button
                type="button"
                className="btn btn-default change-password-button"
                onClick={props.changePasswordToggle}
              >
                Change Password
              </button>
            </li>
          )}
          {props.value.changePasswordOpen && (
            <React.Fragment>
              <li className="list-group-item">
                <label>New Password</label>
                <div className="input-container input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="password-input">
                      <Image src="/assets/icons/flag.svg" />
                    </span>
                  </div>
                  <input
                    required={requiredIfSignup}
                    autoComplete="off"
                    aria-describedby="password-input"
                    type="password"
                    name="password"
                    className="form-input form-control"
                    placeholder="NEW PASSWORD"
                    value={props.value.password}
                    onChange={props.onFormValueChange}
                  />
                </div>
              </li>
              <li className="list-group-item">
                <label>Confirm New Password</label>
                <div className="input-container input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="password-input">
                      <Image src="/assets/icons/flag.svg" />
                    </span>
                  </div>
                  <input
                    required={requiredIfSignup}
                    autoComplete="off"
                    aria-describedby="password-input-1"
                    type="password"
                    name="passwordConfirm"
                    className="form-input form-control"
                    placeholder="CONFIRM NEW PASSWORD"
                    value={props.value.passwordConfirm}
                    onChange={props.onFormValueChange}
                  />
                </div>
              </li>

          
            </React.Fragment>
          )}
          {props.value.passwordNotification && (
            <div className="alert alert-warning">
              Passwords must be the same.
            </div>
          )}
          {props.canChangeType !== false &&
            props.title !== 'SIGN UP' && (
              <li className="list-group-item">
                <div className="options-container">
                  <div
                    className="option-container"
                    onClick={props.onFormOptionChange}
                  >
                    <div
                      className={
                        props.value.accountType === 'seller'
                          ? 'btn-radio-checked'
                          : 'btn-radio'
                      }
                      data-name="seller"
                    />
                    <p className="option" data-name="seller">
                      SELLER
                    </p>
                  </div>
                  <div
                    className="option-container"
                    onClick={props.onFormOptionChange}
                  >
                    <div
                      className={
                        props.value.accountType === 'buyer'
                          ? 'btn-radio-checked'
                          : 'btn-radio'
                      }
                      data-name="buyer"
                    />
                    <p className="option" data-name="buyer">
                      BUYER
                    </p>
                  </div>
                </div>

                {props.value.accountType === 'seller' && (
                    <div className='signup-files-wrap'>
                      <h6>Please upload your account statement here. We will quickly review it and contact you with next steps.</h6>
                      <input type="file" name="selectedFile" className="sellerFileUpload" onChange={props.handleFileUpload} />
                    </div>
                  )}
              </li>
            )}
                
          {props.title === 'SIGN UP' && (
            <li className="list-group-item buyer-seller">
              <h6>Are you a Seller or Buyer?</h6>
              <div className="options-container">
                <div
                  className={`option-container${
                    props.value.accountType === 'seller' ? ' clicked' : ''
                  }`}
                  onClick={props.onFormOptionChange}
                  data-name="seller"
                >
                  <div
                    className={
                      props.value.accountType === 'seller'
                        ? 'btn-radio-checked'
                        : 'btn-radio'
                    }
                    data-name="seller"
                  />
                  <p className="option" data-name="seller">
                    SELLER
                  </p>
                </div>
                <div
                  className={`option-container${
                    props.value.accountType === 'buyer' ? ' clicked' : ''
                  }`}
                  onClick={props.onFormOptionChange}
                  data-name="buyer"
                >
                  <div
                    className={
                      props.value.accountType === 'buyer'
                        ? 'btn-radio-checked'
                        : 'btn-radio'
                    }
                    data-name="buyer"
                  />
                  <p className="option" data-name="buyer">
                    BUYER
                  </p>
                </div>
               
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="card-footer">
        <Button type="submit" className="btn-default">
          SAVE
        </Button>
      </div>
    </form>
  )
}

const mapState = state => {
  return {
    account: state.account
  }
}

export default connect(mapState)(EditProfileForm)
