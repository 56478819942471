import React from 'react'
import {Row, Col, Image} from 'react-bootstrap'
import moment from 'moment'
import {connect} from 'react-redux'
import { useLocation } from 'react-router-dom'

import { 
  LineChart, 
  Line, 
  CartesianGrid, 
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend,
  ResponsiveContainer } from 'recharts';

import Header from '../Header'
import * as Scroll from 'react-scroll'
import Select from 'react-select'
import axios from 'axios'

import './CDS.css'
import { select } from 'async'

const animateScroll = Scroll.animateScroll

let passedLocation = '';

function passPricing(Component) {
  const passedPricing = function (props) {
    const location = useLocation();
    passedLocation = location;
    // console.log(passedLocation);
    return <Component {...props} data={location.data} />;
  };
  return passedPricing;
}


class CDSPricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: {value: '', label: ''},
      selectOptions: [],
      data: []
    }

  }

  componentDidMount() {
    let dataType;
    const props = this.props

    if (passedLocation.state && passedLocation.state.data) {
      dataType = passedLocation.state.data
    }
    animateScroll.scrollTo(0, {smooth: true, duration: 500})
    axios
      .get(`/api/asset-pricing`)
      .then(response => {

        const data = normalizeData(response.data)

        const selectOptions = response.data.map(el => ({
          value: el.name,
          label: el.name
        }));

        // console.log(selectOptions);
        if (!dataType) {
          dataType = selectOptions[0];
        }
        this.setState({
          selectOptions,
          selected: dataType,
          data
        })
      })
      .catch(error => {
        // console.log('error: ', error)
      })
  }

  componentDidUpdate(prevProps) {
    const prevLocation = prevProps.location
    const location = this.props.location
    if (prevLocation && location) {
      const prevState = prevLocation.state
      const state = location.state
      if (prevState && state) {
        const prevData = prevState.data
        const data = state.data
        if (prevData !== data) {
          this.setState(previousState => ({
            selected: data
          }))
        }
      }
    }
  }

  render() {

    
    const rawData = this.state.data.find(el => el.name === this.state.selected.value);
    let data = rawData && rawData.data;

    if (data) {
      data = data.slice(0);
    }
    const colourStyles = {
      dropdownIndicator: styles => ({ ...styles, backgroundColor: '#095CE5' })
    }  
    return (
      
      <div id="cds-chart">
        <Header />
        <div className="cds-chart-header">
          <div className="shade">
            <h1>Pricing Chart</h1>
            <p>Please note that the below prices are delayed. Kindly, contact us for the most up to date pricing.</p>
          </div>
        </div>
        {/* {(this.props.account && this.props.account.email && this.props.account.canViewPricing == true) ? ( */}
        <div className="cds-chart-page container">
          <Select
            options={this.state.selectOptions}
            styles={colourStyles}
            onChange={option => {
              this.setState({selected: option})
            }}
            value={this.state.selected}
            className="dropdown"
          />
          <Row className="chart-section">
            <div className="watermark">
              <Image src="/assets/images/logo-one-black.png" />
            </div>
            <Col md={12}>
              <h3>{this.state.selected.label}<br/> <small className="pricing-by">pricing by Claims Market for claims over $1m</small></h3>
            </Col>
            <ResponsiveContainer
              width="100%"
              aspect={1.5}
              maxHeight={480}
              minWidth={300}
            >
             <LineChart
                data={data}
                margin={{top: 5, right: 20, bottom: 5, left: 0}}
              >
                
                <Line
                  type="monotone"
                  dataKey="Bid"
                  yAxisId="left"
                  stroke="#f5b041"
                  strokeWidth={2}
                  dot={false}
                />
                <Line
                  type="monotone"
                  label="Ask"
                  dataKey="Ask"
                  yAxisId="left"
                  stroke="#095CE5"
                  strokeWidth={2}
                  dot={false}
                />
              
                <XAxis
                  dataKey="Date"
                  tickFormatter={tickItem =>
                    moment(tickItem).format('MM/DD/YY')
                  }
                  angle={295}
                  dy={30}
                  dx={-15}
                  height={80}
                />
                <YAxis
                  tickFormatter={tickItem => tickItem.toFixed(3)}
                  label={{
                    value: 'Bids / Ask',
                    angle: 270,
                    position: 'insideLeft',
                    offset: 10
                  }}
                  width={100}
                  yAxisId="left"
                  unit="%"
                />
                <Legend /> 
                <CartesianGrid stroke='#f5f5f5' vertical={false} /> 
                <Tooltip
                  formatter={(value, label) => {
                    if (label == "Ask") {
                      return [`${value}%`, `${label}`]
                    }
                    if (label == "Bid") {
                      return [`${value}%`, `${label}`]
                    }
                  }}
                  labelFormatter={date => moment(date).format('YYYY-MM-DD')}
                />
              </LineChart>
            </ResponsiveContainer>
          </Row>
        </div> 
        {/* ) : (
          <div className="cds-chart-page container">
            <p>You must log in <u>and</u> be approved by the Administrator to view pricing.</p>
          </div>
        )} */}
      </div>
    )
  }
}

function normalizeData(data) {

  return data.map(el => {
    const info = el.bidAsk.map(d => ({
      Date: d['date'],
      Bid: parseFloat(d['bid']),
      Ask: parseFloat(d['ask'])
    }))
    return {
      name: el.name,
      data: info
    }
  })
}

const mapState = state => {
  return {
    account: state.account
  }
}


export default connect(mapState)(passPricing(CDSPricing))
