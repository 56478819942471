import React from 'react'
import {connect} from 'react-redux'
import {Image, Button} from 'react-bootstrap'
import {listNewClaim} from '../../store'
import './ListNewClaim.css'

class EditProfileForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      leadDebtor: '',
      debtor: '',
      seller: '',
      caseNumber: '',
      claimNumber: '',
      amount: '',
      minAmount: '',
      purchaseRate: '',
      useSAC: true
    }
    this.onFormValueChange = this.onFormValueChange.bind(this)
    this.onFormOptionChange = this.onFormOptionChange.bind(this)
  }

  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onFormOptionChange(event) {
    this.setState({
      useSAC: event.target.dataset.name === 'yes'
    })
  }

  render() {
    return (
      <form
        id="listNewClaim"
        onSubmit={ev => {
          ev.preventDefault()
          this.props.submitNewClaimForm(this.props.account, this.state)
        }}
      >
        <h5>LIST NEW CLAIM</h5>
        {/*{props.account.error &&
					<p style={{color: 'red'}}>{props.account.error.response.data}</p>
			}*/}
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            required
            autoComplete="name"
            type="text"
            name="name"
            className="form-input"
            placeholder="NAME"
            value={this.state.name}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            required
            autoComplete="off"
            type="text"
            name="leadDebtor"
            className="form-input"
            placeholder="LEAD DEBTOR"
            value={this.state.leadDebtor}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            required
            autoComplete="off"
            type="text"
            name="debtor"
            className="form-input"
            placeholder="DEBTOR"
            value={this.state.debtor}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            autoComplete="off"
            type="text"
            name="caseNumber"
            className="form-input"
            placeholder="CASE NUMBER"
            value={this.state.caseNumber}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            autoComplete="off"
            type="text"
            name="claimNumber"
            className="form-input"
            placeholder="CLAIM NUMBER"
            value={this.state.claimNumber}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            autoComplete="off"
            type="text"
            name="amount"
            className="form-input"
            placeholder="AMOUNT"
            value={this.state.amount}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            autoComplete="off"
            type="text"
            name="minAmount"
            className="form-input"
            placeholder="MINIMUM CLAIM AMOUNT"
            value={this.state.minAmount}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            autoComplete="off"
            type="text"
            name="purchaseRate"
            className="form-input"
            placeholder="PURCHASE RATE"
            value={this.state.purchaseRate}
            onChange={this.onFormValueChange}
          />
        </div>
        <p>USE SAC: </p>
        <div className="options-container">
          <div className="option-container">
            <div
              className={this.state.useSAC ? 'btn-radio-checked' : 'btn-radio'}
              data-name="yes"
              onClick={this.onFormOptionChange}
            />
            <p className="option">YES</p>
          </div>
          <div className="option-container">
            <div
              className={!this.state.useSAC ? 'btn-radio-checked' : 'btn-radio'}
              data-name="no"
              onClick={this.onFormOptionChange}
            />
            <p className="option">NO</p>
          </div>
        </div>
        <Button type="submit" className="btn-default">
          SAVE
        </Button>
      </form>
    )
  }
}

const mapState = state => {
  return {
    account: state.account
  }
}

const mapDispatch = dispatch => {
  return {
    submitNewClaimForm(account, claim) {
      const payload = {
        account,
        claim
      }
      dispatch(listNewClaim(payload))
    }
  }
}

export default connect(mapState, mapDispatch)(EditProfileForm)
