import React from 'react'
import {Image, Button} from 'react-bootstrap'
import axios from 'axios'
import history from '../../../history'
import './styles/StepTwo.css'

export default class StepTwo extends React.Component {
  constructor(props) {
    super(props)
    //this.onButtonClick = this.onButtonClick.bind(this)
  }

  //onButtonClick() {
  //  axios
  //    .get('/api/docusign')
  //    .then(res => {
  //      history.push('/profile')
  //    })
  //    .catch(function(error) {})
  //}

  render() {
    return (
      <div id="step-two-other">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="form-section">
                <h5>AGREEMENT</h5>
                <p>
                  By clicking the ‘CONTINUE TO CLAIM’ button below, you accept
                  the <a href="/signup">Terms & Conditions</a> and acknowledge
                  that your data will be used as described in the DocuSign{' '}
                  <a href="/signup">Privacy Policy</a>.
                </p>
                {/*<Button
                  type="submit"
                  className="btn-default"
                  onClick={this.onButtonClick}
                >
                  CONTINUE TO AGREEMENT
                </Button>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
