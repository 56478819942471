import React, {useState} from 'react'
import {connect} from 'react-redux'
import './styles/StepOne.css'
import EditProfileForm from '../../EditProfileForm'
import {signUp, getUser} from '../../../store'

class StepOne extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      title: '',
      company: '',
      phone: '',
      email: '',
      password: '',
      bankruptcyCase: '',
      claimAmount: '',
      askingPrice: '',
      accountType: '',
      error: '',
      validSeller: false,
      
      selectedFile: null,
      isFilePicked: false,
    }


    this.onFormOptionChange = this.onFormOptionChange.bind(this)
    this.onFormValueChange = this.onFormValueChange.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  onFormOptionChange(event) {
    this.setState({
      accountType: event.target.dataset.name
    })
  }

  handleFileUpload(event) {
    this.setState({
      selectedFile: event.target.files,
      isFilePicked: true
    })   
  }

  onFormSubmit(event) {
    event.preventDefault()
    const form = {
      name: this.state.name,
      title: this.state.title,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      bankruptcyCase: this.state.bankruptcyCase,
      claimAmount: this.state.claimAmount,
      askingPrice: this.state.askingPrice,
      accountType: this.state.accountType,
      selectedFile: this.state.selectedFile
    }

    if(form.accountType === 'seller'){

      if(form.bankruptcyCase !== '' && form.claimAmount !== '' && form.askingPrice !== ''){
        this.props.submitSignUpForm(form)
      } else {
        let top = document.getElementById('editProfileForm');
        window.scrollTo(0, top.offsetTop);
        this.setState({
          error: 'Please fill in Bankruptcy Case, Claim Amount and Asking Price'
        })
      }
    }
    else if (isValid(form.email)) {
      this.props.submitSignUpForm(form)
    } else {
      this.setState({
        error: 'Please enter a valid email address.'
      })
    }
  }

  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const form = {
      name: this.state.name,
      title: this.state.title,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      bankruptcyCase: this.state.bankruptcyCase,
      claimAmount: this.state.claimAmount,
      askingPrice: this.state.askingPrice,
      accountType: this.state.accountType,
      selectedFile: this.state.selectedFile
    }
    return (
      <div id="step-one">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="title text-center">
              Please proceed with our two-step onboarding to begin<br />
              selling or buying a claim.
              </p>
            </div>
          </div>
          <EditProfileForm
            title="STEP ONE: SIGN UP"
            error={this.state.error}
            value={form}
            onFormOptionChange={this.onFormOptionChange}
            handleFileUpload={this.handleFileUpload}
            onFormValueChange={this.onFormValueChange}
            onFormSubmit={this.onFormSubmit}
          />
        </div>
      </div>
    )
  }
}

function isValid(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const mapState = state => {
  return {
    user: state.user
  }
}

const mapDispatch = dispatch => {
  return {
    submitSignUpForm(userInfo) {
      dispatch(signUp(userInfo))
    }
  }
}

export default connect(mapState, mapDispatch)(StepOne)
