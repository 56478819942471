import React, {Component} from 'react'

import SortTable from '../Transactions/TransactionsTable';
import './Transactions.css';

export default class Transactions extends React.Component {
  constructor(props) {
    super(props);
  }
  

  render() {
    return (
      <React.Fragment>
        <SortTable />
      </React.Fragment>
    )
  }
}
