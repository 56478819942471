import React, {Component} from 'react'
import InputGroup from '../InputGroup/InputGroup'

export default class NewAssetPricing extends Component {
  constructor(props){
    super(props);
    this.state = {
      selected: false,
      assetPricing: {
        name: '',
        bidAsk: [
          {
            date: '',
            bid: '',
            ask: ''
          }
        ]
  
      }
    }
  
    this.handleChange = this.handleChange.bind(this)
    this.onChange = this.onChange.bind(this)
    this.addFields = this.addFields.bind(this)
    this.removeFields = this.removeFields.bind(this)

  }

  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  
  handleChange = e => {
    // Fetch the name and value of the input that fired the method
    const {name, value} = e.target 
    const i = e.target.getAttribute('data-index-number'); // "3"
    var bidAsk = {...this.state.assetPricing.bidAsk}
    bidAsk[i][name] = value;
    this.setState({bidAsk});
  };

  addFields = e => {
    this.setState({
      assetPricing: { 
        ...this.state.assetPricing, // merge with the original `state.items`
        bidAsk: this.state.assetPricing.bidAsk.concat({date:'', bid: '',ask: ''}) 
      } 
    });
  }

  removeFields = (index) =>{
    this.setState({
      assetPricing: { 
        ...this.state.assetPricing, // merge with the original `state.items`
        bidAsk: this.state.assetPricing.bidAsk.filter((data, idx) => idx !== index)
      } 
    }); 
  }
 
  onChange = (ev, name) => {
    const value = ev.target.value
    this.setState(prevState => {
      const assetPricing = prevState.assetPricing
      assetPricing[name] = value
      return prevState
    })
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <button
            className="btn btn-link"
            type="button"
            data-toggle="collapse"
            onClick={() =>
              this.setState(prevState => ({selected: !prevState.selected}))
            }
          >
            Post New Pricing
          </button>
        </div>

        <div className={`collapse ${this.state.selected ? 'show' : ''}`}>
          <div className="card-body">
                <InputGroup
                  key="name"
                  name="name"
                  value={this.state.assetPricing["name"]}
                  onChange={this.onChange}
                  type="text"
                />
                <InputGroup
                  key="pricingId"
                  name="pricingId"
                  value={this.state.assetPricing["pricingId"]}
                  onChange={this.onChange}
                  type="number"
                  placeholder="Set as Unique Number"
                />


      <div className="mt-3 mb-1">
        <h4>Bid/Ask</h4>
      </div>
			{this.state.assetPricing.bidAsk.map((ba, index) => (  
				<div className="input-group" key={index} data-index={index}>
          	<div className="input-container">
              <input
                type="date"
                name="date"
                className="form-input"
                placeholder="Date"
                value={ba.date}
                data-index-number={index}
                onChange={this.handleChange}
              />
						</div>
						<div className="input-container">
						<input
							type="number"
							name="bid"
							className="form-input"
							placeholder="Bid"
              value={ba.bid}
              data-index-number={index}
              onChange={this.handleChange}
            />
						</div>
		
						<div className="input-container">
							<input
								type="number"
								name="ask"
								className="form-input"
								placeholder="Ask"
                value={ba.ask}
                data-index-number={index}

                onChange={this.handleChange}
								/>
						</div>
            <div className="mb-3">
              <button onClick={() => this.removeFields(index)}>Remove</button>
            </div>
				</div>
			))}
       <div className="mb-3">
              <button onClick={this.addFields}>Add Fields</button>
            </div>
         
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.props.post(this.state.assetPricing)
                this.setState({
                  assetPricing: {
                    name: '',
                    pricingId: 0,
                    bidAsk: [],
                  }
                })
              }}
            >
              Post
            </button>
          </div>
        </div>
      </div>
    )
  }
}
