import React from 'react'
import {Image, Button, Row, Col} from 'react-bootstrap'

import './styles/SectionThree.css'

const SectionThree = () => {
  return (
    <div id="section-three-background">
      <div id="section-three" className="section">
        <Row>
          <Col md={7} className="text-section">
            <div className="title-section">
              <h1>
                Simple Assignment <br />
                of Claim
              </h1>
              <p>
                We have developed a Simple Assignment of Claim agreement (“SAC”)
                that balances the interests of both Sellers and Buyers. The SAC
                gives Buyers assurances they require, such as representations
                and warranties, and the right to proportional repayment in the
                event the claim is allowed for a lower amount than the Minimum
                Claim Amount.
              </p>
            </div>
            <Row>
              <Col md={6}>
                <h5>BENEFITS OF THE SAC</h5>
                <p>
                  The SAC gives Sellers the right to receive additional payment
                  from Buyers in the event the claim is allowed for a higher
                  amount than the Minimum Claim Amount.
                </p>
              </Col>
              <Col md={6}>
                <h5>EFFICIENT CLOSING</h5>
                <p>
                  Claims offered on our platform using the SAC will be signed in
                  advance of the listing by Sellers, providing Buyers with an
                  assured closing. Since all Buyers have agreed to use the SAC, 
                  if a Seller also agrees to use the SAC, transaction costs and 
                  delays are minimized.
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="image-section">
            <Image src="/assets/icons/sac-doc.svg" />
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-market-assets/sac.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW SAC
              </Button>
            </a>

            <p>
            <a>
              <Button
                onClick={() =>
                  window.open(
                    `https://www.youtube.com/watch?v=KhtLCw1SJWA`,
                    '_blank'
                  )
                }
                className="btn btn-default orange-btn"
              >Explainer Video</Button>
            </a>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionThree
