import React from 'react'

import {Button, Row, Col} from 'react-bootstrap'

import {postCryptoContactUsEmail} from '../../../store/ftxContact'
import ReCAPTCHA from 'react-google-recaptcha';

import './styles/SectionSeven.css'

class SectionSeven extends React.Component {
  constructor() {
    super()
    this.state = {
      name: '',
      email: '',
      company: '',
      phone: '',
      claimAmount: '',
      message: '',
      notification: '',
      notificationType: '',
      showNotification: false,
      subject: 'FTX',
      accountStatement: false,
      pricingId: 4,
      bidAsk: [],
      recaptchaToken: '',

    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handleClaimAmountChange = this.handleClaimAmountChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.setRecaptchaToken = this.setRecaptchaToken.bind(this)

  }
  async componentDidMount() {
    let assetPricing = await fetch('/api/asset-pricing/'+this.state.pricingId.toString())
    assetPricing = await assetPricing.json();
    let latest = assetPricing.checkForPricing.bidAsk.slice(-1);
    this.setState({bidAsk: latest[0]})
  }

  async handleSubmit(event) {
    event.preventDefault()
    // console.log(this.state);
    const {email, name, company, phone, claimAmount, message, subject, accountStatement, recaptchaToken} = this.state
    let bidAsk = this.state.bidAsk;
    let notification = ''
    if (this.state.name.length === 0) {
      notification = 'Must input name.'
    }
    if (this.state.email.length === 0) {
      notification = 'Must input an email.'
    }
    if (this.state.message.length === 0) {
      notification = 'Must input a message.'
    }
    if (this.state.company.length === 0) {
      notification = 'Must input a company name.'
    }
    if (this.state.phone.length === 0) {
      notification = 'Must input a phone number.'
    }
    if (this.state.claimAmount.length === 0) {
      notification = 'Must input a claim amount.'
    }
    if (notification.length !== 0) {
      this.setState({
        showNotification: true,
        notificationType: 'warning',
        notification
      })
      return
    }
    let response = await postCryptoContactUsEmail(email, name, company, phone, claimAmount, message, subject, accountStatement, bidAsk, recaptchaToken)
    if (response.data && response.data.success === true) {
      notification = 'Thanks so much for the email!'
      this.setState({
        notification,
        notificationType: 'success',
        showNotification: true
      })
    } else {
      notification = 'Something went wrong!'
      this.setState({
        notification,
        notificationType: 'danger',
        showNotification: true
      })
    }
  }

  handleFileUpload(event) {
    // console.log('handle upload')
    this.setState({
      accountStatement: event.target.files,
    })   
  }

  handleNameChange(event) {
    this.setState({
      name: event.target.value
    })
  }
  handleCompanyChange(event) {
    this.setState({
      company: event.target.value
    })
  }
  handlePhoneChange(event) {
    this.setState({
      phone: event.target.value
    })
  }
  handleClaimAmountChange(event) {
    this.setState({
      claimAmount: event.target.value
    })
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value
    })
  }

  handleMessageChange(event) {
    this.setState({
      message: event.target.value
    })
  }
  setRecaptchaToken(event) {
    this.setState({
      recaptchaToken: event
    })
  }

  render() {
    return (
      <div id="section-seven-background">
        <div id="section-seven" className="section">
          <Row>
            <Col md={4} className="text-section">
              <h1>
                Contact
                <br />
                Us
              </h1>
              <p>
                Claims Market is a platform dedicated to simplifying the
                market for trade claims.
              </p>
              <p>
                We’re happy to help you with the process of listing your claims
                or answer any other questions you may have.
              </p>
              <p>
                1384 Broadway, Suite 906
                <br />
                New York, NY 10018
                <br />
                (212) 259 - 4300
              </p>
            </Col>
            <Col md={{span: 4, order: 2, offset: 2}} className="contact-form-container">
              <form className="contact-form" onSubmit={this.handleSubmit}>
                <input
                  required
                  autoComplete="name"
                  type="text"
                  name="name"
                  placeholder="Your name"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
                <input
                  required
                  autoComplete="email"
                  type="email"
                  name="email"
                  placeholder="Your email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
                <input
                  required
                  autoComplete="false"
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  value={this.state.company}
                  onChange={this.handleCompanyChange}
                />
                <input
                  required
                  autoComplete="false"
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={this.state.phone}
                  onChange={this.handlePhoneChange}
                />
                <input
                  required
                  autoComplete="false"
                  type="number"
                  name="claimAmount"
                  placeholder="Claim Amount (expressed in USD)"
                  value={this.state.claimAmount}
                  onChange={this.handleClaimAmountChange}
                />
                <textarea
                  required
                  autoComplete="off"
                  type="text"
                  name="message"
                  placeholder="Your message"
                  value={this.state.message}
                  onChange={this.handleMessageChange}
                />

                <div className='signup-files-wrap text-center mt-3' style={{'width': '380px' }}>
                    <h6>Please upload here: <br />
a) email from ftx@noticing.ra.kroll.com with subject line: Your Scheduled Claim Information, and b) KYC Process screenshot from claims@ftx.com We will quickly review it and contact you with next steps.</h6>
                    <input type="file" name="statement" className="cryptoFileUpload" onChange={this.handleFileUpload}  />
                  </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <ReCAPTCHA
                    sitekey="6Ld3rl0qAAAAAJS3krtAA_VQopk7z-00UaSCLHG_"
                    onChange={this.setRecaptchaToken}
                  />
                </div>
                <a>
                  <Button type="submit" className="btn-default">
                    Send My Message
                  </Button>
                </a>
              </form>
              {this.state.showNotification && (
                <div
                  className={`alert alert-${this.state.notificationType}`}
                  role="alert"
                >
                  {this.state.notification}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default SectionSeven
