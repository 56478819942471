import React from 'react'
import './styles/SectionTwo.css'


const philosophyDetails = [
  'Markets move fast. We prize agility and creativity.',
  'Our office is optimized for collaboration. We share ideas on strategy, technology and marketing.',
  'We use technology to leverage the talents of our people and help us to better work with our clients.'
];
const philosophyDetailItem = (item, key) => {
  return (
    <div className="detail" key={key}>
      <p>{item}</p>
    </div>
  );
};

const SectionTwo = () => {
  return (
    <div id='section-two'>
      <div className="philosophy">
        <p className='seo-text'>Do you have a Celsius Network account that is caught up in the Celsius bankruptcy ? We can help you to get cash quickly if you are interested in selling your Celsius Claim. Just tap the “Get in touch” button above to reach us.</p>
        <h1 className="title">Our Philosophy</h1>
        <img src="./assets/icons/philosophy.png" />
        <div className="details-container">
          {philosophyDetails.map((detail, i) =>
            philosophyDetailItem(detail, i)
          )}
        </div>
      </div>
    </div>
  )
}

export default SectionTwo
