import React from 'react'
import {Image, Button, Row, Col} from 'react-bootstrap'

import './styles/SPTA.css'

const SPTA = () => {
  return (
    <div id="spta-background">
      <div id="spta" className="section">
        <Row>
          <Col md={7} className="text-section">
            <div className="title-section">
              <h1>
                Simple Pass<br />
                Through Assignment
              </h1>
              <p>
                We have developed a Simple Pass Through Assignment (“SPTA”) to facilitate
                secondary trading of claims. In some cases an intermediary will act as riskless
                principal to accommodate a Buyer’s requirement to face an intermediary. In other
                cases an investor may want to sell a claim to a Buyer without having to make
                representations and warranties customary for an Original Creditor. The SPTA allows
                intermediaries to make representations and warranties that relate only to their own
                acts. Under the SPTA, the Original Creditor’s representations and warranties pass
                through the SPTA for the benefit of the Buyer.
              </p>
            </div>
          </Col>
          <Col md={5} className="image-section">
            <Image src="/assets/icons/sac-doc.svg" />
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-market-assets/spta.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW SPTA
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SPTA

