import React, {Component} from 'react'
import moment from 'moment/moment'


export default class BidAskGroup extends Component {
  constructor(props){
    super(props);

    const possibleFormats = [
      'YYYY-MM-DD', 'YYYY-MM-DD'
    ];
    let baProps = props.value.map(el => {
        return {
          date: moment(el.date, possibleFormats),
          bid: el.bid,
          ask: el.ask
        }
    })
    baProps = baProps.map(el => {
      return {
        date: el.date.format('YYYY-MM-DD'),
        bid: el.bid,
        ask: el.ask
      }
    })

    baProps.sort(function(a, b)
      {
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
      })
    this.state = {
      bidAsk: baProps
    }
  }

 
  onChange = (ev) => {
      const {name, value} = ev.target 
      const i = ev.target.getAttribute('data-index-number'); // "3"
      var bidAsk = {...this.state.bidAsk}
      bidAsk[i][name] = value;
      this.props.onChange(ev, 'bidAskGroup')
      this.setState({bidAsk});
  }

  addNewFields = e => {
    var joined = this.state.bidAsk.concat({date:'', bid:'', ask:''});
    this.props.onChange('pushRow', 'bidAskGroupPush')
    this.setState({ bidAsk: joined })
  }

  removeFields = (index) =>{
    var updatedBidAsk = this.state.bidAsk.filter((data, idx) => idx !== index)
    this.props.onChange(index, 'bidAskGroupPull')
    this.setState({ bidAsk: updatedBidAsk })
  }


  render() {
    return (
      <div>
        <div className="mt-3 mb-1">
          <h4>Bid/Ask</h4>
        </div>

        {Object.keys(this.state.bidAsk).map((name, index) => (  
				  <div className="input-group" key={index} data-index={index}>
          	<div className="input-container">
              <input
                type="date"
                name="date"
                className="form-input"
                placeholder="Date"
                value={this.state.bidAsk[index].date}
                data-index-number={index}
                onChange={this.onChange}
              />
						</div>
              <div className="input-container">
              <input
                type="number"
                name="bid"
                className="form-input"
                placeholder="Bid"
                value={this.state.bidAsk[index].bid}
                data-index-number={index}
                onChange={this.onChange}
              />
              </div>

              <div className="input-container">
                <input
                  type="number"
                  name='ask'
                  className="form-input"
                  placeholder="Ask"
                  value={this.state.bidAsk[index].ask}
                  data-index-number={index}
                  onChange={this.onChange}
                  />
              </div>
              <div className="mb-3">
                <button onClick={() => this.removeFields(index)}>Remove</button>
              </div>
          </div>
        
        ))}

          <div className="mb-3">
            <button onClick={this.addNewFields}>Add Fields</button>
          </div>
  </div>
    )
  }
}
