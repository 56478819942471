import React, {Component} from 'react'
import InputGroup from '../InputGroup/InputGroup'

export default class Card extends Component {
  state = {
    selected: false,
    transaction: {}
  }

  componentDidMount() {
    // console.log(this.props.transaction);
    if (!this.props.transaction.hasOwnProperty('leadDebtor')){
      this.props.transaction.leadDebtor = '';
    }
    this.setState({transaction: this.props.transaction})
  }

  onChange = (ev, name) => {
    const value = ev.target.value
    this.setState(prevState => {
      const transaction = prevState.transaction
      transaction[name] = value
      return prevState
    })
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <button
            className="btn btn-link"
            type="button"
            data-toggle="collapse"
            onClick={() =>
              this.setState(prevState => ({selected: !prevState.selected}))
            }
          >
            {this.state.transaction.name} on{' '}
            {new Date(this.state.transaction.transactionDate).toDateString()}
          </button>
        </div>

        <div className={`collapse ${this.state.selected ? 'show' : ''}`}>
          <div className="card-body">
            {Object.keys(this.state.transaction).map(
              name =>
                !name.match(new RegExp('_id|__v|debtor')) &&(
                  <InputGroup
                    key={name}
                    name={name}
                    value={this.state.transaction[name] || ''}
                    onChange={this.onChange}
                    type={
                      name.match(new RegExp('transactionDate|listDate'))
                        ? 'date'
                        : 'text'
                    }
                  />
                )
            )}

            <div className="btn-group">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.props.delete(this.state.transaction)}
                disabled={this.props.btnsDisabled}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => this.props.update(this.state.transaction)}
                disabled={this.props.btnsDisabled}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
