import React, {Component} from 'react'

function formatDate(date) {
  // console.log(data);
  const newDate = new Date(date)
  if (date !== 'Invalid Date') {
    const formattedDate = [
      newDate.getFullYear(),
      ('0' + (newDate.getMonth() + 1)).slice(-2),
      ('0' + newDate.getDate()).slice(-2)
    ].join('-')
    return formattedDate
  }
  return newDate
}

export default class InputGroup extends Component {
  state = {
    name: '',
    type: 'text'
  }

  componentDidMount() {
    const name = this.props.name
      .split(/(?=[A-Z])/)
      .map(str => str[0].toUpperCase() + str.slice(1))
      .join(' ')
    this.setState({name, type: this.props.type || 'text'})
  }

  render() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">{this.state.name}</span>
        </div>
        <input
          type={this.state.type}
          className="form-control"
          value={
            this.props.type === 'date'
              ? formatDate(this.props.value)
              : this.props.value
          }
          onChange={ev => this.props.onChange(ev, this.props.name)}
        />
      </div>
    )
  }
}
