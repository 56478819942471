import React from 'react'
import {Image, Button} from 'react-bootstrap'
import axios from 'axios'
import {Slide, ToastContainer, toast} from 'react-toastify'
import history from '../../history'
import Header from '../OnboardingHeader'
import './SignAgreement.css'

export default class StepTwo extends React.Component {
  constructor(props) {
    super(props)
    //this.onButtonClick = this.onButtonClick.bind(this)
  }

  //onButtonClick() {
  //  // console.log(this.props);
  //  axios
  //    .get('/api/docusign')
  //    .then(() => {
  //      toast(
  //        'Thank you! You will receive the agreement by email shortly. Please sign the agreement before further actions!',
  //        {
  //          className: 'sign-toast',
  //          bodyClassName: 'sign-toast-body'
  //        }
  //      )
  //      setTimeout(() => history.push('/profile'), 8000)
  //    })
  //    .catch(function(error) {
  //      // console.log(error);
  //    })
  //}

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          transition={Slide}
          autoClose={3000}
          closeButton={false}
        />
        <Header />
        <div id="step-container">
          <div id="sign-agreement">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="form-section card">
                    <div className="card-header text-center">STEP TWO: AGREEMENT</div>
                    <div className="card-body">
                      <p>
                        Thank you for signing up. You will receive an email with an Agreement to sign. Once you have signed the Agreement, you will be able to transact.
                      </p>
                      <p style={{color: '#ffffff', backgroundColor: '#F5B041', padding: '10px', display: 'inline-block'}}>
                        If you do not sign the emailed Agreement, you will not be able to transact or view claims listed for sale.
                      </p>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
