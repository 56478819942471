import React from 'react'
import './styles/SectionOne.css'
import * as Scroll from 'react-scroll'


const animateScroll = Scroll.animateScroll

const checkLocation = (position) => {
  setTimeout(function() {
    var destination = document.querySelector('#' + position)
    const elementPosition = destination.offsetTop - 50
    animateScroll.scrollTo(elementPosition, {
      smooth: true,
      duration: 500
    })
  }, 200)
}


const SectionOne = () => {
  return (
    <React.Fragment>

    <div className="scene-video-container">
      <video autoPlay loop muted className="scene-video">
        <source src="/assets/media/hero_video.mp4" type="video/mp4" />
        <source src="/assets/media/hero_video.webm" type="video/webm" />
        {/* <source src='/assets/media/hero_video.ogv' type='video/ogg' /> */}
      </video>
    </div>
     <div className="scene-gradient"></div>
      <div className="scene-white-wedge"></div>
        <div className='section-one'>
          <div className="intro">
            <div className="title-container">
              <h1 className="title">Celsius Claims</h1>
                <h3 className="subtitle"><span className="bold">Cherokee Acquisition</span> is actively buying bankruptcy claims. We focus relentlessly on providing great service to our clients.  Please tap the button below to contact us. We will respond quickly!</h3>
                <div className='crypto-contact'>
                <a
                      className="btn"
                      onClick={() =>
                        checkLocation('section-seven-background')
                      }
                    >Get In Touch</a>
              </div>
            </div>
          </div>
        </div>

     </React.Fragment>
  )
}

export default SectionOne