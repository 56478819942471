import React from 'react'
import {connect} from 'react-redux'
import {Image, Button} from 'react-bootstrap'
import * as Scroll from 'react-scroll'

import history from '../../../history'
import {fetchAllUsers} from '../../../store'

import './ListClaim.css'

const animateScroll = Scroll.animateScroll

function addDollarAndFraction(numberString) {
  return `$${Number(numberString).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`
}

function createTable(selectedClaims) {
  const columns = []
  selectedClaims.forEach(claim => {
    const column = createColumn(claim)
    columns.push(column)
  })
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">DOCUMENT<br />NAME</th>
            <th scope="col">DEBTOR</th>
            <th scope="col">CASE #</th>
            <th scope="col">CLAIM #</th>
            <th scope="col">AMOUNT</th>
            <th scope="col" className="min-claim-amount">
              MINIMUM<wbr />CLAIM AMOUNT
            </th>
            <th scope="col">ADMIN FEE<wbr/>(BUYER)</th>
            <th scope="col">DOCUMENT<br />COPY</th>
          </tr>
        </thead>
        <tbody>{columns}</tbody>
      </table>
    </div>
  )
}

function createColumn(claim) {
  let {
    _id,
    name,
    debtor,
    caseNumber,
    claimNumber,
    amount,
    minAmount,
    adminFee,
    ext
  } = claim

  if (!debtor) debtor = 'N/A'
  if (!caseNumber) caseNumber = 'N/A'
  if (!claimNumber) claimNumber = 'N/A'
  if (!amount) amount = 'N/A'
  if (!minAmount) minAmount = 'N/A'
  if (!adminFee) adminFee = 'N/A'

  return (
    <tr key={_id}>
      <td className="title">
        <p>{name}</p>
      </td>
      <td>
        <p>{debtor}</p>
      </td>
      <td className="right">
        <p>{caseNumber}</p>
      </td>
      <td className="right">
        <p>{claimNumber}</p>
      </td>
      <td className="right">
        <p>{addDollarAndFraction(amount)}</p>
      </td>
      <td className="right">
        <p>{addDollarAndFraction(minAmount)}</p>
      </td>
      <td className="right">
        <p>{addDollarAndFraction(adminFee)}</p>
      </td>
      <td>
        <div className="image-text-wrap">
          <Image src="/assets/icons/doc.svg" />
          <a
            href={`https://s3.us-east-2.amazonaws.com/claims-market-assets/claims/${_id}.${ext ? ext : 'pdf'}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            VIEW
          </a>
        </div>
      </td>
    </tr>
  )
}

class ListClaim extends React.Component {
  componentDidMount() {
    this.props.initAllUsers()
    if (!this.props.selectedLeadDebtor || !this.props.selectedSeller) {
      history.push('/')
    }
    animateScroll.scrollTo(0, {smooth: true, duration: 500})
  }

  render() {
    const selectedClaims = this.props.claims.filter(
      claim =>
        claim.leadDebtor === this.props.selectedLeadDebtor &&
        claim.creditor === this.props.selectedSeller
    )

    const table = createTable(selectedClaims)

    return (
      <div id="list-claim">
        <div>
          <div className="row top-row">
            <div className="col">
              <div className="image-section mx-auto">
                <p>{this.props.selectedLeadDebtor.toUpperCase()}</p>
                <Image src="/assets/icons/arrow-right.svg" />
                <p>{this.props.selectedSeller.toUpperCase()}</p>
              </div>
            </div>
          </div>
          <div className="form-section card mx-auto">
            <div className="card-header text-center">CLAIMS DILIGENCE</div>
            <div className="card-body mx-auto">{table}</div>
            <div className="card-footer text-center">
              <Button
                type="submit"
                className="btn-default"
                onClick={() => this.props.setCreateClaimStep(2, selectedClaims)}
              >
                CONTINUE TO TRADE SUMMARY
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims,
    selectedLeadDebtor: state.selectedLeadDebtor,
    selectedSeller: state.selectedSeller
  }
}

const mapDispatch = dispatch => {
  return {
    initAllUsers() {
      dispatch(fetchAllUsers())
    }
  }
}

export default connect(mapState, mapDispatch)(ListClaim)
