import React from 'react'
import {connect} from 'react-redux'
import {Image, Button} from 'react-bootstrap'
import axios from 'axios'
import './AdminClaimItem.css'
const moment = require('moment')


class AdminClaimItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        ...this.props.info
      },
      tempFile: null,
      tabOpen: false,
      deleteOccurring: false,
      deleteMessage: '',
      deletedOrSold: false,
      emailsOccurring: false,
      emailsMessage: '',
      ext: null
    }
    this.handleTabChange = this.handleTabChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onCheckChange = this.onCheckChange.bind(this)
  }

  handleTabChange() {
    this.setState(prevState => ({
      tabOpen: !prevState.tabOpen
    }))
  }

  onInputChange(event) {
    const key = event.target.name
    const value = event.target.value
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [key]: value
      }
    }))
  }


  onCheckChange(event) {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [event.target.name]: !this.state.form.tradingStatus
      }
    }))
  }

  submitFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.tempFile[0])
    axios
      .post(`/api/claim/${this.props.info.id}/fileupdate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          tabOpen: false,
          ext: this.state.tempFile[0].type.split('/').pop()
        })
      })
      .catch(error => {
        // handle your error
      })
  }

  handleFileUpload = event => {
    this.setState({
      tempFile: event.target.files
    })
  }

  handleClaimDelete = () => {
    const confirm = window.confirm(
      'Are you sure that you would like to delete this?'
    )
    if (!confirm) {
      return
    }
    this.delete()
  }

  delete = async type => {
    const account = this.props.account
    const claim = this.props.info
    const soldOrDeleted = type
    this.setState({deleteOccurring: true})
    let result
    try {
      result = await fetch('/api/claim/delete', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({account, claim, soldOrDeleted})
      })
      result = await result.json()
    } catch (err) {
      result = {success: false}
    }
    if (result.success) {
      if (type === 'sold') {
        this.setState({deleteOccurring: false})
      } else {
        this.setState({deleteOccurring: false, deletedOrSold: true})
      }
      return {deleted: true}
    } else {
      this.setState({
        deleteOccurring: false,
        deleteMessage:
          'Something went wrong. Please try again in a few minutes.'
      })
      setTimeout(() => {
        this.setState({deleteMessage: ''})
      }, 4000)
      const box = document.getElementsByClassName('creditor-name')
      // console.log(box.innerHTML)
      return {deleted: true}
    }
  }

  handleSold = async () => {
    const confirm = window.confirm(
      'Are you sure that you would like to notify all users that this is sold and delete the claim?'
    )
    if (!confirm) {
      return
    }
    //this.delete('sold') need to put this back

    const account = this.props.account
    const claim = this.props.info
    this.setState({emailsOccurring: true})
    let result
    try {
      result = await fetch('/api/claim/sold', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({account, claim})
      })
      result = await result.json()
    } catch (err) {
      result = {success: false}
    }
    if (result.success) {
      this.setState({emailsOccurring: false, deletedOrSold: true})
    } else {
      this.setState({
        emailsOccurring: false,
        emailsMessage:
          'Something went wrong. Please try again in a few minutes.'
      })
      setTimeout(() => {
        this.setState({emailsMessage: ''})
      }, 4000)
    }
  }

  // eslint-disable-next-line complexity
  render() {
    const labelMap = {
      name: 'Name',
      debtor: 'Debtor',
      leadDebtor: 'Lead Debtor',
      creditor: 'Seller',
      caseNumber: 'Case Number',
      amount: 'Amount',
      minAmount: 'Minimum Claim Amount',
      adminFee: 'Admin Fee',
      note: 'Note',
      tradingStatus: 'Seller Engaged',
      claimNumber: 'Claim Number',
      purchaseRate: 'Purchase Rate',
      expireDate: 'Expire Date',
      seller: 'Original Creditor (SPTA)'
    }
    return (
      (this.state.deletedOrSold === false && (
        <div id="admin-claim-item">
          <div className="subjectContainer">
            <div className="creditor-name">
              {this.props.info.creditor} - {this.props.info.name}  
              {this.props.info.tradingStatus === 'true' && ( <strong> - Seller Engaged with Buyer</strong> )}
            </div>
            <div className="tabs">
              <button
                type="button"
                className="btn btn-danger"
                disabled={this.state.deleteOccurring}
                onClick={this.handleClaimDelete}
              >
                Delete
              </button>
              {this.state.deleteMessage && (
                <div className="alert alert-danger" role="alert">
                  {this.state.deleteMessage}
                </div>
              )}
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.emailsOccurring}
                onClick={this.handleSold}
              >
                Sold
              </button>
              {this.state.emailsMessage && (
                <div className="alert alert-danger" role="alert">
                  {this.state.emailsMessage}
                </div>
              )}
              {this.state.tabOpen ? (
                <Image
                  src="/assets/icons/minus.svg"
                  onClick={this.handleTabChange}
                />
              ) : (
                <Image
                  src="/assets/icons/plus.svg"
                  onClick={this.handleTabChange}
                />
              )}
            </div>
          </div>
          {this.state.tabOpen && (
            <div className="editFormContainer">

              {Object.keys(this.props.info).map(item => {
                if (item !== 'id' && item !== 'ext' && item !== 'expireDate' && item !== 'tradingStatus') {
                  return (
                    <div key={item} className="input-container">
                      <label className="option">{labelMap[item]}</label>
                      <input
                        type="text"
                        name={item}
                        value={this.state.form[item]}
                        onChange={this.onInputChange}
                      />
                    </div>
                  ) 
                } else if(item === 'tradingStatus'){
                  // console.log(this.state.form);
                  return(
                    <div key={item} className="input-container">
                      <label className="option">{labelMap[item]}</label>
                      <input
                        type="checkbox"
                        name={item}
                        checked={this.state.form[item] == 'true' || this.state.form[item] == true ?  true : false}
                        onChange={this.onCheckChange}
                      />
                    </div>
                  )
                  
                } else if(item === 'expireDate') {
                  let expireDate;
                  if(this.state.form[item] !== null){
                    expireDate = this.state.form[item];
                    expireDate = moment.utc(expireDate).format('YYYY-MM-DD');
                  }

                  return (
                    <div key={item} className="input-container">
                      <label className="option">{labelMap[item]}</label>
                      <input
                        type="date"
                        name={item}
                        value={this.state.form[item] === null
                          ? ''
                          : expireDate
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  )

                }
              })}
              <div className="btn-container">
                <Button
                  className="btn-default"
                  onClick={() => {
                    this.props.editItemFormSubmit(
                      this.props.info.id,
                      this.state.form
                    )
                    this.setState({tabOpen: false})
                  }}
                >
                  SAVE
                </Button>
              </div>
              <a
                href={`https://s3.us-east-2.amazonaws.com/claims-market-assets/claims/${
                  this.props.info.id
                }.${
                  this.state.ext
                    ? this.state.ext
                    : this.props.info.ext
                    ? this.props.info.ext
                    : 'pdf'
                }`}
                target="_blank"
              >
                View Document
              </a>
              <form className="file-container" onSubmit={this.submitFile}>
                <label className="option">Document</label>
                <input type="file" onChange={this.handleFileUpload} />
                <button type="submit" className="btn-send">
                  Update
                </button>
              </form>
            </div>
          )}
        </div>
      )) ||
      null
    )
  }
}

const mapState = state => {
  return {
    account: state.account
  }
}

const mapDispatch = dispatch => {
  return {}
}

export default connect(mapState, mapDispatch)(AdminClaimItem)
