import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import history from './history'
import './index.css'
import store from './store'
import Routes from './App'

//import * as Sentry from "@sentry/react";
//import { BrowserTracing } from "@sentry/tracing";
//
//Sentry.init({
//  dsn: "https://bc6c1ffb93c248ee8f9654b2addfc781@o423412.ingest.sentry.io/6416700",
//  integrations: [new BrowserTracing()],
//
//  // Set tracesSampleRate to 1.0 to capture 100%
//  // of transactions for performance monitoring.
//  // We recommend adjusting this value in production
//  tracesSampleRate: 1.0,
//});

const root = ReactDOM.createRoot(document.getElementById('app'));

root.render( 
  <Provider store={store}>
    <Router history={history}>
      <Routes />
    </Router>
  </Provider>,
);