import React from 'react'
import * as Scroll from 'react-scroll'

import Header from '../Header'
import {
  SectionOne,
  SectionTwo,
  SectionSeven

} from './Sections'


const animateScroll = Scroll.animateScroll

class Voyager extends React.Component {
  componentDidMount() {
    animateScroll.scrollTo(0, {smooth: true, duration: 500})
  }
  render() {
    return (
      <React.Fragment>
        <Header {...this.props} />
        <SectionOne />
        <SectionTwo />
        <SectionSeven />
      </React.Fragment>
    )
  }
}

export default Voyager
