import React from 'react'
import {connect} from 'react-redux'
import {Image, Row, Col, Button} from 'react-bootstrap'

import history from '../../../history'
import {setSelectedLeadDebtor, setSelectedSeller} from '../../../store'

import './styles/SectionOne.css'

class SectionOne extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLeadDebtor: '',
      selectedSeller: '',
      currentTab: 'leadDebtor',
      formError: ''
    }
    this.toSelectDebtor = this.toSelectDebtor.bind(this)
    this.toSelectSeller = this.toSelectSeller.bind(this)
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.handleSellerSelect = this.handleSellerSelect.bind(this)
    this.continueClick = this.continueClick.bind(this)
  }

  componentDidMount() {
    if (this.props.claims && this.props.claims[0]) {
      this.setState({
        selectedLeadDebtor: this.props.claims[0].leadDebtor
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claims !== this.props.claims) {
      if (this.props.claims && this.props.claims[0]) {
        this.setState(
          {
            selectedLeadDebtor: this.props.claims[0].leadDebtor
          },
          () => {
            // uncheck all radio buttons
            const radioBtns = document.querySelectorAll('.form-check input')
            radioBtns.forEach(btn => {
              btn.checked = false
            })
          }
        )
      }
    }
  }

  toSelectSeller() {
    if (!this.state.selectedLeadDebtor) {
      this.setState({
        formError: 'Please Select Lead Debtor'
      })
    } else {
      this.setState({
        currentTab: 'sellers'
      })
    }
  }

  toSelectDebtor() {
    this.setState({
      currentTab: 'leadDebtor'
    })
  }

  handleOptionClick(event) {

    this.setState({
      selectedLeadDebtor: event.target.dataset.name,
      formError: '',
      currentTab: 'sellers'
    })
    history.push('/sellers');


  }

  handleSellerSelect(event) {
    const allSellerOptions = document.querySelectorAll('.seller-option')
    allSellerOptions.forEach(option => option.classList.remove('selected'))
    event.target.classList.add('selected')

    this.props.dispatchSelectedLeadDebtor(this.state.selectedLeadDebtor)
    this.props.dispatchSelectedSeller(event.target.dataset.name)

    // Note: I added this 1/26
    this.setState({
      selectedSeller: event.target.dataset.name
    });
    history.push('/createclaim')
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  continueClick() {}

  createDebtorOptions = claims => {
    if (claims) {
      const leadDebtorSet = new Set()
      claims.forEach(el => leadDebtorSet.add(el.leadDebtor))
      let leadDebtorArr = [...leadDebtorSet]
      leadDebtorArr = leadDebtorArr.sort();
      return leadDebtorArr.map((el, i) => {
        return (
          <div
            key={i}
            className="list-group-item text-center debtor-option"
            onClick={this.handleOptionClick}
            data-name={el}
          >
            {el}
          </div>
        )
      })
    }
  }

  createSellerOptions = claims => {
    // const sellerSet = new Set()
    let userAccount = this.props.account;
    // let selectedLeadDebtor = this.state.selectedLeadDebtor;
    // console.log(claims);
    // const sellerOptions = claims.filter(function(claim){
    //   if(userAccount.accountType == 'seller' && claim.seller == userAccount.company){

    //     return true;
    //   } else if(userAccount.accountType !== 'seller' && claim.leadDebtor === selectedLeadDebtor){
    //     return true;
    //   }
    //   return false;
    // })

    const sellerSet = new Set()
    const sellerOptions = claims.filter(
      claim => claim.leadDebtor === this.state.selectedLeadDebtor
    )

    sellerOptions.forEach(function(claim){
      if(userAccount.accountType == 'seller' && claim.seller == userAccount.company){
        sellerSet.add(claim.creditor)
      } else if (userAccount.accountType !== 'seller'){
        sellerSet.add(claim.creditor)
      }
    })

    const sellerArr = [...sellerSet]

    return sellerArr.map(el => {
      const claimOptions = sellerOptions.filter(claim => claim.creditor === el)
      const avg = this.getAvg(claimOptions)
      const avgRate = (avg.rate * 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      const avgAmount = avg.minAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return (
        <div
          key={el}
          className="list-group-item text-center seller-option"
          onClick={this.handleSellerSelect}
          data-name={el}
        >
          {'$' + avgAmount + ' at ' + avgRate + '%'}
        </div>
      )
    })
  }

  getAvg = claims => {
    const total = claims.reduce((acc, cur) => acc + Number(cur.minAmount), 0)
    const consideration = claims.reduce(
      (acc, cur) => acc + Number(cur.minAmount) * Number(cur.purchaseRate),
      0
    )
    return {minAmount: total / claims.length, rate: consideration / total}
  }

  render() {
    return (
      <div id="section-one-background">
        <div id="section-one" className="section">
          <Row>
            <Col md={6}>
              <div className="text-section-container">
                <div>
                  <h1>Trade Claims Simplified</h1>
                  <p>
                    Claims Market is a platform dedicated to simplifying the
                    market for trade claims.
                  </p>
    
                  
                </div>
                <Row>
                  <Col md={6}>
                    <h5>Streamlined Process</h5>
                    <p>
                      We streamline the process of selling and buying claims by
                      helping Sellers upload all relevant documents to our site.
                    </p>
                  </Col>
                  <Col md={6}>
                    <h5>Savings</h5>
                    <p>
                      We save you money on legal fees by offering a Simple
                      Assignment of Claim ("SAC") agreement that is fair to both
                      Sellers and Buyers.
                    </p>
                  </Col>
                  
                </Row>

                <Row>
                  <Col md={12}>
                  <div className='embed-container'>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Bv8QvHg0jNc?autoplay=1&mute=1&loop=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>
                  </Col>
                </Row>
                
              </div>
            </Col>
            <Col md={6} className="onboarding-form-container">
              <div className="onboarding-form">
                {this.state.formError && (
                  <p style={{color: 'red'}}>{this.state.formError}</p>
                )}
                {this.state.currentTab === 'leadDebtor' ? (
                  <div className="card">
                    <div className="card-header text-center">
                      <h5>
                        <strong>Select Lead Debtor</strong>
                      </h5>
                    </div>
                    <ul className="list-group list-group-flush list-one">
                      {this.createDebtorOptions(this.props.claims)}
                    </ul>
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-header text-center">
                      <h5>
                        <strong>Select Seller</strong>
                      </h5>
                    </div>
                    <ul className="list-group list-group-flush list-two">
                      {this.props.account && this.props.claims
                        ? this.props.account.accountType
                          ? this.props.account.agreementSigned &&
                            this.createSellerOptions(this.props.claims)
                          : 'Please sign up to view the list of sellers.'
                        : null}
                    </ul>
                    <div
                      className="back-btn mx-auto"
                      onClick={this.toSelectDebtor}
                    >
                      <Image src="/assets/icons/arrow-left.svg" />
                      Go Back
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims
  }
}

const mapDispatch = dispatch => {
  return {
    dispatchSelectedLeadDebtor(debtor) {
      dispatch(setSelectedLeadDebtor(debtor))
    },
    dispatchSelectedSeller(seller) {
      dispatch(setSelectedSeller(seller))
    }
  }
}

export default connect(mapState, mapDispatch)(SectionOne)
