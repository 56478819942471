
import React, { useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { COLUMNS } from '../Transactions/Columns';
import Header from '../Header';
import { Row, Col } from 'react-bootstrap';

const SortTable = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(0);


	const Columns = COLUMNS;
	const calculateTotalAmount = (transactions) => {
		const value = transactions.reduce((amount, transaction) => 
			amount += parseFloat(transaction.minAmount.substring(1).replace(/,/g, '')), 0);
		
		return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	}


	const getData = async () => {
		fetch('/api/transaction')
		.then((response) => response.json())
		.then((transactions) => {
			for (const i in transactions) {
				let transaction = transactions[i];		
				transaction.minAmount = 
					`$${Number(transaction.minAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
			}
	
			setData(transactions);
			setTotal(calculateTotalAmount(transactions));
			setLoading(false);

		})
		.catch((err) => { /* Todo: Add Error Handling */ });
	}

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ 
			columns: Columns, 
			data: data,
			initialState: {
				sortBy: [{ id: "transactionDate", desc: true }]
			},    
			// Here --------
			sortTypes: {
				custom: (rowA, rowB, columnId) => {
				  	let a = rowA.values[columnId];
				 	let b = rowB.values[columnId];
					a = Number(a.replace(/(^\$|,)/g,''));
					b = Number(b.replace(/(^\$|,)/g,''));
					if (!isNaN(a) && !isNaN(b) ){
						return Number(a) - Number(b);
					}
				  	return a.localeCompare(b);
				}
			  },
			  // -------------
		}, useSortBy);


	
	useEffect(() => {
		setLoading(true);
		getData();

	}, []);
		

	return (
		<React.Fragment>
			<Header />
			<div className="transactions transactions-bg ">
			<div className="transactions-section section">

				<Row>
				<Col md={1} />

				<Col md={10}>
					<div>
						<h1 className="display-4">Transaction History</h1>
						<p className="lead">
						Creditors have sold{' '}
						<span className="text-success">
							<span className="dollar">$</span>
							<span className="underline">
							{ total }
							</span>
						</span>{' '}
						of claims through Claims Market in { data.length } transactions.
						</p>
					</div>
		

					<div className="table-responsive">
						<table {...getTableProps()} className="table table-dark">
							<thead className="thead-light">
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th {...column.getHeaderProps(column.getSortByToggleProps())}>
												{column.render('Header')}
												<span >
													{column.isSorted
														? column.isSortedDesc
															? ' ↓'
															: ' ↑'
														: ''}
												</span>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.map((row) => {
									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return (
													<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</Col>

				<Col md={1} />
				</Row>
			</div>
			</div>
		</React.Fragment>
	);
};

export default SortTable;