import React, {Component} from 'react'

import Card from './Card/Card'
import NewAssetPricing from './NewAssetPricing/NewAssetPricing'

import './TransactionsPanelContent.css'


export default class AssetPricingPanelContent extends Component {
  state = {
    assetPricing: [],
    btnsDisabled: false
  }

  async componentDidMount() {
    let allAPS = await fetch('/api/asset-pricing')
    const assetPricing = await allAPS.json();
    this.setState({assetPricing})
  }

  update = async update => {
    let updatedAssetPricing = await fetch('/api/asset-pricing/update', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({update, id: update._id})
    })
    try {
      updatedAssetPricing = await updatedAssetPricing.json()
    } catch (err) {
      // console.log('error updating transaction', err)
    }
    //const filteredTransactions = this.state.transactions.filter(
    //  currentTransaction => currentTransaction._id !== update._id
    //)
    //const transactions = sortByDate([...filteredTransactions, update])
    //const filteredAssetPricing = this.state.assetPricing.filter(
    //  currentAssetPricing => currentAssetPricing._id !== update._id
    //)
//
    //this.setState({assetPricing: filteredAssetPricing})

    this.setState({btnsDisabled: false})
  }

  delete = async assetPricing => {
    const check = confirm('Are you sure you want to delete this?')
    if (!check) {
      return
    }
    let deletedAssetPricing = await fetch('/api/asset-pricing/delete', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({id: assetPricing._id})
    })
    try {
      deletedAssetPricing = await deletedAssetPricing.json()
    } catch (err) {
      // console.log('error deleting assetPricing', err)
    }
    const filteredAssetPricing = this.state.assetPricing.filter(
      currentAssetPricing => currentAssetPricing._id !== assetPricing._id
    )

    this.setState({assetPricing: filteredAssetPricing})
  }

  post = async newAssetPricing => {

    let postedAssetPricing = await fetch('/api/asset-pricing', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({newAssetPricing})
    })
    try {
      postedAssetPricing = await postedAssetPricing.json()
    } catch (err) {
      // console.log('error posting transaction', err)
    }
    const assetPricing = [
      ...this.state.assetPricing,
      postedAssetPricing.assetPricing
    ]

    this.setState({assetPricing})
  }

  createCards = () => {
    return this.state.assetPricing.map(aps => {
      const id = aps._id
      return (
        <Card
          key={id}
          assetPricing={aps}
          delete={this.delete}
          update={this.update}
          btnsDisabled={this.state.btnsDisabled}
        />
      )
    })
  }

  render() {
    return (
      <div id="panel-content" className="transaction-panel">
        <div className="accordion">
          {this.state.assetPricing.length > 0 && this.createCards()}
          <NewAssetPricing
            post={this.post}
            btnsDisabled={this.state.btnsDisabled}
          />
        </div>
      </div>
    )
  }
}
