import React from 'react'
import {connect} from 'react-redux'
import {Image, Button} from 'react-bootstrap'
import './AddClaimForm.css'

class AddClaimForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sellerId: '',
      name: '',
      leadDebtor: '',
      debtor: '',
      seller: '',
      creditor: '',
      caseNumber: '',
      claimNumber: '',
      amount: '',
      minAmount: '',
      adminFee: '',
      note: '',
      tradingStatus: '',
      purchaseRate: '',
      file: null,
      submitting: false,
      message: '',
      expireDate: '',
      sendEmail: true
    }
    this.onFormValueChange = this.onFormValueChange.bind(this)
    this.onCheckValueChange = this.onCheckValueChange.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
  }

  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onCheckValueChange(event) {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  handleFileUpload = event => {
    this.setState({
      selectedFile: event.target.files
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateForm !== prevProps.updateForm) {
      this.setState({
        message: 'Finished posting new claim!',
        submitting: false,
        sellerId: '',
        name: '',
        leadDebtor: '',
        debtor: '',
        seller: '',
        creditor: '',
        caseNumber: '',
        claimNumber: '',
        amount: '',
        minAmount: '',
        adminFee: '',
        note: '',
        tradingStatus: '',
        purchaseRate: '',
        file: null,
        expireDate: '',
        sendEmail: true
      })
    }
  }

  render() {
    return (
      <form
        id="addClaimForm"
        onSubmit={e => {
          e.preventDefault()
          this.setState({submitting: true})
          const useSAC = this.props.account && this.props.account.hasRedlineSac ? false : true;
          const useSPTA = this.props.account && this.props.account.hasRedlineSPTA ? false : true;

          const data = {...this.state, sellerId: this.props.account.id, useSAC: useSAC, useSPTA: useSPTA }
          this.props.addItemFormSubmit(data)
        }}
      >
        <h5>ADD CLAIM</h5>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="sellerId"
            className="form-input"
            placeholder="SELLER ID (OPTIONAL)"
            value={this.state.sellerId}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            autoComplete="name"
            type="text"
            name="name"
            className="form-input"
            placeholder="NAME"
            value={this.state.name}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="leadDebtor"
            className="form-input"
            placeholder="LEAD DEBTOR"
            value={this.state.leadDebtor}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="creditor"
            className="form-input"
            placeholder="SELLER"
            value={this.state.creditor}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="debtor"
            className="form-input"
            placeholder="DEBTOR"
            value={this.state.debtor}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="seller"
            className="form-input"
            placeholder="ORIGINAL CREDITOR (SPTA)"
            value={this.state.seller}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="caseNumber"
            className="form-input"
            placeholder="CASE NUMBER"
            value={this.state.caseNumber}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="claimNumber"
            className="form-input"
            placeholder="CLAIM NUMBER"
            value={this.state.claimNumber}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="amount"
            className="form-input"
            placeholder="AMOUNT"
            value={this.state.amount}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="minAmount"
            className="form-input"
            placeholder="MINIMUM CLAIM AMOUNT"
            value={this.state.minAmount}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="purchaseRate"
            className="form-input"
            placeholder="PURCHASE RATE"
            value={this.state.purchaseRate}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="adminFee"
            className="form-input"
            placeholder="ADMIN FEE"
            value={this.state.adminFee}
            onChange={this.onFormValueChange}
          />
        </div>
        <div className="file-container">
        <label className="option">Expire Date</label>
          <input
            type="date"
            name="expireDate"
            className="form-input"
            placeholder="Claim Expire Date"
            value={this.state.expireDate}
            onChange={this.onFormValueChange}
          />
        </div>

        <div className="input-container">
          <Image src="/assets/icons/flag.svg" />
          <input
            type="text"
            name="note"
            className="form-input"
            placeholder="NOTE"
            value={this.state.note}
            onChange={this.onFormValueChange}
          />
        </div>

        <div className="file-container" >
          <label className="option">Seller Engaged</label>
          <input
            type="checkbox"
            name="tradingStatus"
            checked={this.state.tradingStatus == 'true' ?  true : false}
            onChange={this.onCheckValueChange}
          />
        </div>

        <div className="file-container" onSubmit={this.submitFile}>
          <label className="option">Document</label>
          <input type="file" onChange={this.handleFileUpload} />
        </div>

        <div className="file-container" >
          <label className="option">Send Email</label>
          <input
                name="sendEmail"
                type="checkbox"
                checked={this.state.sendEmail}
                onChange={this.onCheckValueChange} />
        </div>
        <Button
          type="submit"
          className="btn-default"
          disabled={this.state.submitting}
        >
          SUBMIT
        </Button>
        {this.state.message && (
          <div className="alert alert-info">{this.state.message}</div>
        )}
      </form>
    )
  }
}

const mapState = state => {
  return {
    account: state.account
  }
}

export default connect(mapState)(AddClaimForm)
