import React from 'react'
import {Image, Button} from 'react-bootstrap'
import './styles/StepFour.css'

const tradeSummary = {
  wec: {
    min: '$0.00',
    consideration: '$0.00'
  },
  pci: {
    min: '$79,641.00',
    consideration: '$55,749.14'
  },
  wecems: {
    min: '$567.00',
    consideration: '$342.20'
  },
  fee: {
    min: '$1,500.00',
    consideration: '$1,500.00'
  },
  total: {
    min: '$81,708.63',
    consideration: '$57,589.34'
  }
}

export default class StepFour extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      company: '',
      phone: '',
      email: '',
      selectedSide: 'buyer'
    }
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleOptionClick(event) {
    this.setState(
      {
        selectedSide: event.target.dataset.name
      },
      function() {}
    )
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div id="step-four">
        <p className="title">
          Please proceed with our three-step onboarding to begin<br />
          selling or buying a claim.
        </p>
        <div className="image-section">
          <p>WESTINGHOUSE</p>
          <Image src="/assets/icons/arrow-right.svg" />
          <p>MULTIMETAL</p>
        </div>
        <div className="form-section">
          <h5>PUT OPTION</h5>
          <div className="subject-section">
            <div className="subject-container">
              <p className="subject">DEBTOR</p>
              <div>
                <p>Westinghouse</p>
                <div />
              </div>
            </div>
            <div className="subject-container">
              <p className="subject">CREDITOR</p>
              <div>
                <p>Multimetal Products Corporation</p>
                <div />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="gridRow title">
              <p className="gridCol">DEBTOR</p>
              <p className="gridCol">MIN. CLAIM</p>
              <p className="gridCol">CONSIDERATION</p>
            </div>
            <div>
              <div className="gridRow">
                <p className="gridCol">WEC</p>
                <p className="gridCol">{tradeSummary.wec.min}</p>
                <p className="gridCol">{tradeSummary.wec.consideration}</p>
              </div>
              <div className="gridRow">
                <p className="gridCol">PCI</p>
                <p className="gridCol">{tradeSummary.pci.min}</p>
                <p className="gridCol">{tradeSummary.pci.consideration}</p>
              </div>
              <div className="gridRow">
                <p className="gridCol">WEC EMS</p>
                <p className="gridCol">{tradeSummary.wecems.min}</p>
                <p className="gridCol">{tradeSummary.wecems.consideration}</p>
              </div>
            </div>
            <div className="gridRow fee">
              <p className="gridCol">CLAIMS MARKET FEE</p>
              <p className="gridCol">{tradeSummary.fee.min}</p>
              <p className="gridCol">{tradeSummary.fee.consideration}</p>
            </div>
            <div className="gridRow total">
              <p className="gridCol">TOTAL</p>
              <p className="gridCol">{tradeSummary.total.min}</p>
              <p className="gridCol">{tradeSummary.total.consideration}</p>
            </div>
          </div>
          <div className="link-container">
            <Image src="/assets/icons/doc.svg" />
            <p>VIEW SAC</p>
          </div>
          <Button type="submit" className="btn-default">
            TRADE CONFIRMATION
          </Button>
        </div>
      </div>
    )
  }
}
