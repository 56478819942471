import React from 'react'
import * as Scroll from 'react-scroll'
import './style.css'
const axios = require('axios');
import Header from '../Header'
import { Link } from 'react-router-dom'

class NotSigned extends React.Component {

  constructor(props) {
    super(props)
  }



  async componentDidMount() {
    // console.log(this.props);
    // console.log('testing');
    if(this.props.account){
        // console.log('we have an account');
        try {
            const res = await axios.get('/auth/check-signed')
            // console.log(res);
        } catch(error){

        }
  }
   
  }


  render() {
    return (
        <div id="not-signed">
            <Header />
            <div className="not-signed-body">
                <h2>You Document has not been signed yet.</h2>
            </div>
        </div>
    )
  }
}

export default NotSigned
