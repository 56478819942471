import axios from 'axios'

/**
/**ACTION TYPES
 */

const GET_ALL_SELLERS = 'GET_ALL_SELLERS'

/**
 * INITIAL STATE
 */
const defaultSellers = []

/**
 * ACTION CREATORS
 */
export const getAllSellers = sellers => ({type: GET_ALL_SELLERS, sellers})

/**
 * THUNK CREATORS
 */
export const fetchAllSellers = () => async dispatch => {
  try {
    const res = await axios.get('/api/account/all-sellers')
    dispatch(getAllSellers(res.data || defaultSellers))
  } catch (err) {
    // do something
  }
}

/**
 * REDUCER
 */
export default function(state = defaultSellers, action) {
  switch (action.type) {
    case GET_ALL_SELLERS:
      return action.sellers
    default:
      return state
  }
}
