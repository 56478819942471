import React from 'react'
import Header from '../OnboardingHeader'
import { withRouter } from "react-router-dom";

import Steps from './Steps'

const CreateNewClaim = () => {
  // console.log('loading create new claim');
  return (
    <React.Fragment>
      <Header />
      <Steps />
    </React.Fragment>
  )
}

export default withRouter(CreateNewClaim)
