import {createStore, combineReducers, applyMiddleware} from 'redux'
import createLogger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import account from './account'
import claims from './claims'
import users from './users'
import sellers from './sellers'
import buyers from './buyers'
import assetPricing from './assetPricing'
import selectedLeadDebtor from './selectedLeadDebtor'
import selectedSeller from './selectedSeller'

const reducer = combineReducers({
  account,
  claims,
  users,
  sellers,
  buyers,
  assetPricing,
  selectedLeadDebtor,
  selectedSeller
})
const middleware = composeWithDevTools(
  // applyMiddleware(thunkMiddleware, createLogger({collapsed: true}))
  applyMiddleware(thunkMiddleware)
)
const store = createStore(reducer, middleware)

export default store

export * from './claims'
export * from './account'
export * from './users'
export * from './sellers'
export * from './buyers'
export * from './assetPricing'
export * from './selectedLeadDebtor'
export * from './selectedSeller'
