import React from 'react'
import {Image, Button} from 'react-bootstrap'
import './styles/StepThree.css'

export default class StepThree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      company: '',
      phone: '',
      email: '',
      selectedSide: 'buyer'
    }
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleOptionClick(event) {
    this.setState(
      {
        selectedSide: event.target.dataset.name
      },
      function() {}
    )
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div id="step-three">
        <p className="title">
          Please proceed with our two-step onboarding to begin <br />
          buying or listing claims.
        </p>
        <div className="image-section">
          <p>WESTINGHOUSE</p>
          <Image src="/assets/icons/arrow-right.svg" />
          <p>MULTIMETAL</p>
        </div>
        <div className="form-section">
          <h5>CLAIMS OFFERED</h5>
          <div className="select-container">
            <p className="select-option left">PROOF OF CLAIM</p>
            <p className="select-option">SCHEDULE F(1)</p>
            <p className="select-option right">SCHEDULE F(2)</p>
          </div>
          <div className="description-container">
            <p className="description-label">DEBTOR</p>
            <p className="description-content">
              Westinghouse Electric Co. LLC “WEC”
            </p>
          </div>
          <div className="description-container">
            <p className="description-label">CASE NUMBER</p>
            <p className="description-content">17-10751</p>
          </div>
          <div className="description-container">
            <p className="description-label">CLAIM NUMBER</p>
            <p className="description-content">410</p>
          </div>
          <div className="description-container">
            <p className="description-label">AMOUNT</p>
            <p className="description-content">70.000%</p>
          </div>
          <div className="description-container">
            <p className="description-label">OFFER PRICE</p>
            <p className="description-content">$83,558.83</p>
          </div>
          <div className="link-container">
            <Image src="/assets/icons/doc.svg" />
            <p>VIEW PROOF OF CLAIM</p>
          </div>
          <Button
            type="submit"
            className="btn-default"
            onClick={() => this.props.setOnboardingStep(4)}
          >
            CONTINUE TO TRADE SUMMARY
          </Button>
        </div>
      </div>
    )
  }
}
