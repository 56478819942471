import React from 'react'
import * as Scroll from 'react-scroll'

import Header from '../Header'
import {
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  SectionSix,
  SectionSeven,
  SPTA,
  CDS,
  SectionFtx

} from './Sections'
import FAQ from '../FAQ'
import { Link } from 'react-router-dom'
import axios from 'axios'

const animateScroll = Scroll.animateScroll

class Landing extends React.Component {

  constructor(props) {
    super(props),
    this.checkLocation = this.checkLocation.bind(this)
  }



  checkLocation(){
    let scroll = Scroll.animateScroll;
    this.forceUpdate()
    if(window.location.hash){
      let section = document.getElementById(window.location.hash.slice(1));
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      scroll.scrollTo(0, {smooth: true, duration: 500})
    }
  }

  componentDidMount() {
      this.checkLocation();
  }


  render() {
    return (
      <React.Fragment>
        <Header {...this.props} />
        <SectionFtx/>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <CDS />
        <FAQ />
        <SPTA />
        <SectionSix />
        <SectionSeven />
      </React.Fragment>
    )
  }
}


export default Landing
